/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #869ab8;
    --gray-dark: #384c74;
    --primary: #165985;
    --secondary: #506690;
    --success: #42ba96;
    --info: #7c69ef;
    --warning: #fad776;
    --danger: #df4759;
    --light: #f9fbfd;
    --dark: #2b354f;
    --primary-desat: #6c8aec;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
:after,
:before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(22, 28, 45, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: HKGroteskPro, serif;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0!important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit
}

address,
dl,
ol,
ul {
    margin-bottom: 1rem
}

dl,
ol,
ul {
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #165985;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #133bbd;
    text-decoration: underline
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    border-style: none
}

img,
svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: 1.5rem 2rem;
    padding-bottom: 1.5rem 2rem;
    color: #869ab8;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none!important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 400;
    line-height: 1
}

.h1,
h1 {
    font-size: 2.3125rem
}

.h2,
h2 {
    font-size: 1.9375rem
}

.h3,
h3 {
    font-size: 1.3125rem
}

.h4,
h4 {
    font-size: 1.1875rem
}

.h5,
h5 {
    font-size: 1.0625rem
}

.h6,
h6 {
    font-size: .75rem
}

.lead {
    font-size: 1.3125rem;
    font-weight: 400
}

.display-1 {
    font-size: 4.375rem
}

.display-1,
.display-2 {
    font-weight: 400;
    line-height: 1
}

.display-2 {
    font-size: 3.8125rem
}

.display-3 {
    font-size: 3rem
}

.display-3,
.display-4 {
    font-weight: 400;
    line-height: 1
}

.display-4 {
    font-size: 2.6875rem
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #f1f4f8
}

.small,
small {
    font-size: 88.2%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote-footer {
    display: block;
    font-size: .75rem;
    color: #869ab8
}

.blockquote-footer:before {
    content: "\2014\00A0"
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #d9e2ef;
    border-radius: .375rem
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #869ab8
}

code {
    font-size: .8125rem;
    color: #e83e8c;
    word-break: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .8125rem;
    color: #fff;
    background-color: #2b354f;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600
}

pre {
    display: block;
    font-size: .8125rem;
    color: #2b354f
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1040px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-sm-first {
        order: -1
    }
    .order-sm-last {
        order: 13
    }
    .order-sm-0 {
        order: 0
    }
    .order-sm-1 {
        order: 1
    }
    .order-sm-2 {
        order: 2
    }
    .order-sm-3 {
        order: 3
    }
    .order-sm-4 {
        order: 4
    }
    .order-sm-5 {
        order: 5
    }
    .order-sm-6 {
        order: 6
    }
    .order-sm-7 {
        order: 7
    }
    .order-sm-8 {
        order: 8
    }
    .order-sm-9 {
        order: 9
    }
    .order-sm-10 {
        order: 10
    }
    .order-sm-11 {
        order: 11
    }
    .order-sm-12 {
        order: 12
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.33333%
    }
    .offset-sm-2 {
        margin-left: 16.66667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.33333%
    }
    .offset-sm-5 {
        margin-left: 41.66667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.33333%
    }
    .offset-sm-8 {
        margin-left: 66.66667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.33333%
    }
    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-md-first {
        order: -1
    }
    .order-md-last {
        order: 13
    }
    .order-md-0 {
        order: 0
    }
    .order-md-1 {
        order: 1
    }
    .order-md-2 {
        order: 2
    }
    .order-md-3 {
        order: 3
    }
    .order-md-4 {
        order: 4
    }
    .order-md-5 {
        order: 5
    }
    .order-md-6 {
        order: 6
    }
    .order-md-7 {
        order: 7
    }
    .order-md-8 {
        order: 8
    }
    .order-md-9 {
        order: 9
    }
    .order-md-10 {
        order: 10
    }
    .order-md-11 {
        order: 11
    }
    .order-md-12 {
        order: 12
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.33333%
    }
    .offset-md-2 {
        margin-left: 16.66667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.33333%
    }
    .offset-md-5 {
        margin-left: 41.66667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.33333%
    }
    .offset-md-8 {
        margin-left: 66.66667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.33333%
    }
    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-lg-first {
        order: -1
    }
    .order-lg-last {
        order: 13
    }
    .order-lg-0 {
        order: 0
    }
    .order-lg-1 {
        order: 1
    }
    .order-lg-2 {
        order: 2
    }
    .order-lg-3 {
        order: 3
    }
    .order-lg-4 {
        order: 4
    }
    .order-lg-5 {
        order: 5
    }
    .order-lg-6 {
        order: 6
    }
    .order-lg-7 {
        order: 7
    }
    .order-lg-8 {
        order: 8
    }
    .order-lg-9 {
        order: 9
    }
    .order-lg-10 {
        order: 10
    }
    .order-lg-11 {
        order: 11
    }
    .order-lg-12 {
        order: 12
    }
    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.33333%
    }
    .offset-lg-2 {
        margin-left: 16.66667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.33333%
    }
    .offset-lg-5 {
        margin-left: 41.66667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.33333%
    }
    .offset-lg-8 {
        margin-left: 66.66667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.33333%
    }
    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-xl-first {
        order: -1
    }
    .order-xl-last {
        order: 13
    }
    .order-xl-0 {
        order: 0
    }
    .order-xl-1 {
        order: 1
    }
    .order-xl-2 {
        order: 2
    }
    .order-xl-3 {
        order: 3
    }
    .order-xl-4 {
        order: 4
    }
    .order-xl-5 {
        order: 5
    }
    .order-xl-6 {
        order: 6
    }
    .order-xl-7 {
        order: 7
    }
    .order-xl-8 {
        order: 8
    }
    .order-xl-9 {
        order: 9
    }
    .order-xl-10 {
        order: 10
    }
    .order-xl-11 {
        order: 11
    }
    .order-xl-12 {
        order: 12
    }
    .offset-xl-0 {
        margin-left: 0
    }
    .offset-xl-1 {
        margin-left: 8.33333%
    }
    .offset-xl-2 {
        margin-left: 16.66667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.33333%
    }
    .offset-xl-5 {
        margin-left: 41.66667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.33333%
    }
    .offset-xl-8 {
        margin-left: 66.66667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.33333%
    }
    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #161c2d;
    background-color: #fff
}

.table td,
.table th {
    padding: 1.5rem 2rem;
    vertical-align: top;
    border-top: 1px solid #f1f4f8
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f4f8
}

.table tbody+tbody {
    border-top: 2px solid #f1f4f8
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #f1f4f8
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9fbfd
}

.table-hover tbody tr:hover {
    color: #161c2d;
    background-color: rgba(22, 28, 45, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #c6d2f9
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #95abf4
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #afc0f7
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #ced4e0
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #a4afc5
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #bec6d6
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #caece2
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #9ddbc8
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b7e5d8
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #dad5fb
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #bbb1f7
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #c5bef9
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #fef4d9
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #fceab8
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fdedc0
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f6cbd1
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #ee9fa9
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f2b5be
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fdfefe
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #fcfdfe
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ecf6f6
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c4c6ce
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #9196a3
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b6b9c2
}

.table-primary-desat,
.table-primary-desat>td,
.table-primary-desat>th {
    background-color: #d6defa
}

.table-primary-desat tbody+tbody,
.table-primary-desat td,
.table-primary-desat th,
.table-primary-desat thead th {
    border-color: #b3c2f5
}

.table-hover .table-primary-desat:hover,
.table-hover .table-primary-desat:hover>td,
.table-hover .table-primary-desat:hover>th {
    background-color: #bfccf7
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: rgba(22, 28, 45, .075)
}

.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(14, 17, 28, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #384c74;
    border-color: #445d8e
}

.table .thead-light th {
    color: #161c2d;
    background-color: #fff;
    border-color: #f1f4f8
}

.table-dark {
    color: #fff;
    background-color: #384c74
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #445d8e
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .075)
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--primary);
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #161c2d;
    background-color: #fff;
    border-color: #a7b9f6;
    outline: 0;
    box-shadow: none
}

.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
    color: #869ab8;
    opacity: 1
}

.form-control::placeholder {
    color: #869ab8;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f1f4f8;
    opacity: 1
}

select.form-control:focus::-ms-value {
    color: #161c2d;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.8125rem + 1px);
    padding-bottom: calc(.8125rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6
}

.col-form-label-lg {
    padding-top: calc(1.122rem + 1px);
    padding-bottom: calc(1.122rem + 1px);
    font-size: 1.0625rem;
    line-height: 1.3
}

.col-form-label-sm {
    padding-top: calc(.5625rem + 1px);
    padding-bottom: calc(.5625rem + 1px);
    font-size: 1.0625rem;
    line-height: 1.3
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .8125rem;
    padding-bottom: .8125rem;
    margin-bottom: 0;
    line-height: 1.6;
    color: #161c2d;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.3em + 1.125rem + 2px);
    padding: .5625rem 1rem
}

.form-control-lg,
.form-control-sm {
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem
}

.form-control-lg {
    height: calc(1.3em + 2.244rem + 2px);
    padding: 1.122rem 1.5rem
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 10px;
    padding-left: 10px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
    color: #869ab8
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 88.2%;
    color: #42ba96
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .9375rem;
    line-height: 1.6;
    color: #fff;
    background-color: rgba(66, 186, 150, .9);
    border-radius: .375rem
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #42ba96
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #42ba96
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
    display: block
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #42ba96
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #42ba96
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
    border-color: #42ba96
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
    border-color: #66c9ab;
    background-color: #66c9ab
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #42ba96
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 88.2%;
    color: #df4759
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .9375rem;
    line-height: 1.6;
    color: #fff;
    background-color: rgba(223, 71, 89, .9);
    border-radius: .375rem
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #df4759
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #df4759
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
    display: block
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #df4759
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #df4759
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
    border-color: #df4759
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
    border-color: #e77280;
    background-color: #e77280
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #df4759
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #df4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        justify-content: center
    }
    .form-inline .form-group,
    .form-inline label {
        display: flex;
        align-items: center;
        margin-bottom: 0
    }
    .form-inline .form-group {
        flex: 0 0 auto;
        flex-flow: row wrap
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }
    .form-inline .form-control-plaintext {
        display: inline-block
    }
    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }
    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }
    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 600;
    color: #161c2d;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .8125rem 1.25rem;
    font-size: 1.0625rem;
    line-height: 1.6;
    border-radius: .375rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #161c2d;
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #165985;
    border-color: #165985;
}

.btn-primary:hover {
    color: #fff;
    background-color: #2e59d9;
    border-color: #2653d4;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #165985;
    border-color: #165985;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2653d4;
    border-color: #244ec9;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #717384;
    border-color: #6b6d7d;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6b6d7d;
    border-color: #656776;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
}

.btn-success:hover {
    color: #fff;
    background-color: #17a673;
    border-color: #169b6b;
}

.btn-success:focus,
.btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #169b6b;
    border-color: #149063;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
}

.btn-info:hover {
    color: #fff;
    background-color: #2c9faf;
    border-color: #2a96a5;
}

.btn-info:focus,
.btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #2a96a5;
    border-color: #278c9b;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
}

.btn-warning {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
}

.btn-warning:hover {
    color: #fff;
    background-color: #f4b619;
    border-color: #f4b30d;
}

.btn-warning:focus,
.btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f4b30d;
    border-color: #e9aa0b;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
}

.btn-danger:hover {
    color: #fff;
    background-color: #e02d1b;
    border-color: #d52a1a;
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d52a1a;
    border-color: #ca2819;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
}

.btn-light {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
}

.btn-light:hover {
    color: #3a3b45;
    background-color: #dde2f1;
    border-color: #d4daed;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #3a3b45;
    background-color: #d4daed;
    border-color: #cbd3e9;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
}

.btn-dark:hover {
    color: #fff;
    background-color: #484a54;
    border-color: #42444e;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #42444e;
    border-color: #3d3e47;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}

.btn-outline-primary {
    color: #165985;
    border-color: #165985;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #165985;
    border-color: #165985;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #165985;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #165985;
    border-color: #165985;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}

.btn-outline-secondary {
    color: #858796;
    border-color: #858796;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #858796;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}

.btn-outline-success {
    color: #1cc88a;
    border-color: #1cc88a;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #1cc88a;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}

.btn-outline-info {
    color: #36b9cc;
    border-color: #36b9cc;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #36b9cc;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}

.btn-outline-warning {
    color: #f6c23e;
    border-color: #f6c23e;
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f6c23e;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}

.btn-outline-danger {
    color: #e74a3b;
    border-color: #e74a3b;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #e74a3b;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}

.btn-outline-light {
    color: #f8f9fc;
    border-color: #f8f9fc;
}

.btn-outline-light:hover {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fc;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}

.btn-outline-dark {
    color: #5a5c69;
    border-color: #5a5c69;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #5a5c69;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #165985;
    text-decoration: none
}

.btn-link:hover {
    color: #133bbd;
    text-decoration: underline
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: underline;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #869ab8;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 1.122rem 1.5rem
}

.btn-group-lg>.btn,
.btn-group-sm>.btn,
.btn-lg,
.btn-sm {
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .5625rem 1rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1.0625rem;
    color: #161c2d;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(22, 28, 45, .15);
    border-radius: .375rem
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropright .dropdown-toggle:after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    display: none
}

.dropleft .dropdown-toggle:before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropleft .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #f1f4f8
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #2b354f;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #222a3e;
    text-decoration: none;
    background-color: #f9fbfd
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #165985
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #869ab8;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .9375rem;
    color: #869ab8;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1.5rem;
    color: #2b354f
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .9375rem;
    padding-left: .9375rem
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio],
.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .8125rem 1.25rem;
    margin-bottom: 0;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #869ab8;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #f1f4f8;
    border-radius: .375rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.3em + 2.244rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: 1.122rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.3em + 1.125rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .5625rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.3;
    border-radius: .375rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 2.25rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.7rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #165985;
    background-color: #165985
}

.custom-control-input:focus~.custom-control-label:before {
    box-shadow: none
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #a7b9f6
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
    color: #fff;
    background-color: #d5defb;
    border-color: #d5defb
}

.custom-control-input:disabled~.custom-control-label {
    color: #869ab8
}

.custom-control-input:disabled~.custom-control-label:before {
    background-color: #f1f4f8
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label:before {
    pointer-events: none;
    background-color: #d9e2ef;
    border: 0 solid #abbcd5
}

.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: .35rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: ""
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label:before {
    border-radius: .375rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    border-color: #165985;
    background-color: #165985
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(51, 94, 234, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
    background-color: rgba(51, 94, 234, .5)
}

.custom-radio .custom-control-label:before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(51, 94, 234, .5)
}

.custom-switch {
    padding-left: 3rem
}

.custom-switch .custom-control-label:before {
    left: -3rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: .5rem
}

.custom-switch .custom-control-label:after {
    top: calc(.35rem + 0px);
    left: calc(-3rem + 0px);
    width: 1rem;
    height: 1rem;
    background-color: #abbcd5;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label:after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #d9e2ef;
    transform: translateX(1.5rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(51, 94, 234, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    padding: .8125rem 2.25rem .8125rem 1.25rem;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.25rem center/24px 24px;
    background-color: #fff;
    border: 1px solid #f1f4f8;
    border-radius: .375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #a7b9f6;
    outline: 0;
    box-shadow: none
}

.custom-select:focus::-ms-value {
    color: #161c2d;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none
}

.custom-select:disabled {
    color: #869ab8;
    background-color: #f1f4f8
}

.custom-select::-ms-expand {
    display: none
}

.custom-select-sm {
    height: calc(1.3em + 1.125rem + 2px);
    padding-top: .5625rem;
    padding-bottom: .5625rem;
    padding-left: 1rem;
    font-size: 1.0625rem
}

.custom-select-lg {
    height: calc(1.3em + 2.244rem + 2px);
    padding-top: 1.122rem;
    padding-bottom: 1.122rem;
    padding-left: 1.5rem;
    font-size: 1.0625rem
}

.custom-file {
    display: inline-block;
    margin-bottom: 0
}

.custom-file,
.custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px)
}

.custom-file-input {
    z-index: 2;
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #a7b9f6;
    box-shadow: none
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #f1f4f8
}

.custom-file-input:lang(en)~.custom-file-label:after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]:after {
    content: attr(data-browse)
}

.custom-file-label {
    left: 0;
    z-index: 1;
    height: calc(1.6em + 1.625rem + 2px);
    font-weight: 400;
    color: #161c2d;
    background-color: #fff;
    border: 1px solid #f1f4f8;
    border-radius: .375rem
}

.custom-file-label,
.custom-file-label:after {
    position: absolute;
    top: 0;
    right: 0;
    padding: .8125rem 1.25rem;
    line-height: 1.6
}

.custom-file-label:after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.625rem);
    color: #506690;
    content: "Browse";
    background-color: #f1f4f8;
    border-left: inherit;
    border-radius: 0 .375rem .375rem 0
}

.custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: none
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, none
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, none
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, none
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #165985;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #d5defb
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d9e2ef;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #165985;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #d5defb
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d9e2ef;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #165985;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #d5defb
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
    background-color: #d9e2ef;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #abbcd5
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #abbcd5
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #abbcd5
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .custom-control-label:before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #869ab8;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #d9e2ef
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #f1f4f8 #f1f4f8 #d9e2ef
}

.nav-tabs .nav-link.disabled {
    color: #869ab8;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #506690;
    background-color: #fff;
    border-color: #d9e2ef #d9e2ef #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .375rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #165985
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    padding: 1.25rem 20px
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .15rem;
    padding-bottom: .15rem;
    margin-right: 20px;
    font-size: 1.5rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.1875rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .375rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%
}

@media (max-width:575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-md .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #165985
}

.navbar-light .navbar-nav .nav-link {
    color: #506690
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #165985
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(22, 28, 45, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #165985
}

.navbar-light .navbar-toggler {
    color: #506690;
    border-color: transparent
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23506690' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: #506690
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: #165985
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: #fff;
    border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text,
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f1f4f8;
    border-radius: .375rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.card-body {
    flex: 1 1 auto;
    padding: 2rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 2rem
}

.card-header {
    padding: .75rem 2rem;
    margin-bottom: 0;
    background-color: rgba(22, 28, 45, .03);
    border-bottom: 0 solid #f1f4f8
}

.card-header:first-child {
    border-radius: calc(.375rem - 0px) calc(.375rem - 0px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 2rem;
    background-color: rgba(22, 28, 45, .03);
    border-top: 0 solid #f1f4f8
}

.card-footer:last-child {
    border-radius: 0 0 calc(.375rem - 0px) calc(.375rem - 0px)
}

.card-header-tabs {
    margin-bottom: -.75rem;
    border-bottom: 0
}

.card-header-pills,
.card-header-tabs {
    margin-right: -1rem;
    margin-left: -1rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.375rem - 0px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.375rem - 0px);
    border-top-right-radius: calc(.375rem - 0px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.375rem - 0px);
    border-bottom-left-radius: calc(.375rem - 0px)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 20px
}

@media (min-width:576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -20px;
        margin-left: -20px
    }
    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px
    }
}

.card-group {
    display: flex;
    flex-direction: column
}

.card-group>.card {
    margin-bottom: 20px
}

@media (min-width:576px) {
    .card-group {
        flex-flow: row wrap
    }
    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }
    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card .card-header,
.breadcrumb {
    margin-bottom: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item:before {
    display: inline-block;
    padding-right: .5rem;
    color: #506690;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none
}

.breadcrumb-item.active {
    color: #506690
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .375rem
}

.page-link {
    position: relative;
    display: block;
    padding: .8125rem 1.25rem;
    margin-left: -1px;
    line-height: 1.6;
    color: #165985;
    background-color: #fff;
    border: 1px solid #d9e2ef
}

.page-link:hover {
    z-index: 2;
    color: #133bbd;
    text-decoration: none;
    background-color: #f1f4f8;
    border-color: #d9e2ef
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #165985;
    border-color: #165985
}

.page-item.disabled .page-link {
    color: #869ab8;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #d9e2ef
}

.pagination-lg .page-link {
    padding: 1.122rem 1.5rem;
    font-size: 1.1875rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem
}

.pagination-sm .page-link {
    padding: .5625rem 1rem;
    font-size: .9375rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .25rem .75rem;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .375rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .75rem;
    padding-left: .75rem;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #165985
}

a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
    background-color: #1643d4
}

a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(51, 94, 234, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #506690
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #fff;
    background-color: #3e4f6f
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(80, 102, 144, .5)
}

.badge-success {
    color: #fff;
    background-color: #42ba96
}

a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #359478
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, .5)
}

.badge-info {
    color: #fff;
    background-color: #7c69ef
}

a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #543bea
}

a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(124, 105, 239, .5)
}

.badge-warning {
    color: #2b354f;
    background-color: #fad776
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #2b354f;
    background-color: #f8c945
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0 hsla(44, 93%, 72%, .5)
}

.badge-danger {
    color: #fff;
    background-color: #df4759
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #cf2438
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, .5)
}

.badge-light {
    color: #2b354f;
    background-color: #f9fbfd
}

a.badge-light:focus,
a.badge-light:hover {
    color: #2b354f;
    background-color: #d3e2f0
}

a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(249, 251, 253, .5)
}

.badge-dark {
    color: #fff;
    background-color: #2b354f
}

a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
    background-color: #191f2e
}

a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(43, 53, 79, .5)
}

.badge-primary-desat {
    color: #fff;
    background-color: #6c8aec
}

a.badge-primary-desat:focus,
a.badge-primary-desat:hover {
    color: #fff;
    background-color: #3f66e6
}

a.badge-primary-desat.focus,
a.badge-primary-desat:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(108, 138, 236, .5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #f1f4f8;
    border-radius: .5rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .375rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 600
}

.alert-dismissible {
    padding-right: 4.09375rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #253e8f
}

.alert-primary .alert-link {
    color: #1b2c66
}

.alert-secondary {
    color: #344260
}

.alert-secondary .alert-link {
    color: #222b3f
}

.alert-success {
    color: #2d6e64
}

.alert-success .alert-link {
    color: #1e4a43
}

.alert-info {
    color: #4b4492
}

.alert-info .alert-link {
    color: #39346f
}

.alert-warning {
    color: #8d7d53
}

.alert-warning .alert-link {
    color: #6d6140
}

.alert-danger {
    color: #7f3244
}

.alert-danger .alert-link {
    color: #5a2430
}

.alert-light {
    color: #8c9099
}

.alert-light .alert-link {
    color: #727680
}

.alert-dark {
    color: #21293f
}

.alert-dark .alert-link {
    color: #0f131e
}

.alert-primary-desat {
    color: #435590
}

.alert-primary-desat .alert-link {
    color: #33406d
}

@keyframes a {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .79688rem;
    background-color: #f1f4f8;
    border-radius: .375rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #165985;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: a 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #506690;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #506690;
    text-decoration: none;
    background-color: #f9fbfd
}

.list-group-item-action:active {
    color: #161c2d;
    background-color: #f1f4f8
}

.list-group-item {
    position: relative;
    display: block;
    padding: 1.5rem 2rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #f1f4f8
}

.list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #869ab8;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #165985;
    border-color: #165985
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: 0
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }
    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: .375rem;
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }
    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: .375rem;
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }
    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: .375rem;
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }
    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: .375rem;
        border-bottom-left-radius: .375rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: 0
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0
}

.list-group-item-primary {
    color: #253e8f;
    background-color: #c6d2f9
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #253e8f;
    background-color: #afc0f7
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #253e8f;
    border-color: #253e8f
}

.list-group-item-secondary {
    color: #344260;
    background-color: #ced4e0
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #344260;
    background-color: #bec6d6
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #344260;
    border-color: #344260
}

.list-group-item-success {
    color: #2d6e64;
    background-color: #caece2
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #2d6e64;
    background-color: #b7e5d8
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #2d6e64;
    border-color: #2d6e64
}

.list-group-item-info {
    color: #4b4492;
    background-color: #dad5fb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #4b4492;
    background-color: #c5bef9
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #4b4492;
    border-color: #4b4492
}

.list-group-item-warning {
    color: #8d7d53;
    background-color: #fef4d9
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #8d7d53;
    background-color: #fdedc0
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8d7d53;
    border-color: #8d7d53
}

.list-group-item-danger {
    color: #7f3244;
    background-color: #f6cbd1
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #7f3244;
    background-color: #f2b5be
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f3244;
    border-color: #7f3244
}

.list-group-item-light {
    color: #8c9099;
    background-color: #fdfefe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #8c9099;
    background-color: #ecf6f6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8c9099;
    border-color: #8c9099
}

.list-group-item-dark {
    color: #21293f;
    background-color: #c4c6ce
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #21293f;
    background-color: #b6b9c2
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #21293f;
    border-color: #21293f
}

.list-group-item-primary-desat {
    color: #435590;
    background-color: #d6defa
}

.list-group-item-primary-desat.list-group-item-action:focus,
.list-group-item-primary-desat.list-group-item-action:hover {
    color: #435590;
    background-color: #bfccf7
}

.list-group-item-primary-desat.list-group-item-action.active {
    color: #fff;
    background-color: #435590;
    border-color: #435590
}

.close {
    float: right;
    font-size: 1.59375rem;
    font-weight: 600;
    line-height: 1;
    color: #869ab8;
    text-shadow: none;
    opacity: .5
}

.close:hover {
    color: #869ab8;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .25rem .75rem rgba(22, 28, 45, .1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #869ab8;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translateY(-50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
    content: none
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(22, 28, 45, .2);
    border-radius: .5rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #161c2d
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #f1f4f8;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.6
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #f1f4f8;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.modal-footer>:not(:first-child) {
    margin-left: .25rem
}

.modal-footer>:not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }
    .modal-dialog-centered:before {
        height: calc(100vh - 3.5rem)
    }
    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: HKGroteskPro, serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9375rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow:before,
.bs-tooltip-top .arrow:before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #161c2d
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow:before,
.bs-tooltip-right .arrow:before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #161c2d
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #161c2d
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow:before,
.bs-tooltip-left .arrow:before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #161c2d
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #161c2d;
    border-radius: .375rem
}

.popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-family: HKGroteskPro, serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9375rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(22, 28, 45, .2);
    border-radius: .5rem
}

.popover,
.popover .arrow {
    position: absolute;
    display: block
}

.popover .arrow {
    width: 1rem;
    height: .5rem;
    margin: 0 .5rem
}

.popover .arrow:after,
.popover .arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
    bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top]>.arrow:before,
.bs-popover-top>.arrow:before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(22, 28, 45, .25)
}

.bs-popover-auto[x-placement^=top]>.arrow:after,
.bs-popover-top>.arrow:after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .5rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow:before,
.bs-popover-right>.arrow:before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(22, 28, 45, .25)
}

.bs-popover-auto[x-placement^=right]>.arrow:after,
.bs-popover-right>.arrow:after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom]>.arrow:before,
.bs-popover-bottom>.arrow:before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(22, 28, 45, .25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow:after,
.bs-popover-bottom>.arrow:after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
    right: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .5rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow:before,
.bs-popover-left>.arrow:before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(22, 28, 45, .25)
}

.bs-popover-auto[x-placement^=left]>.arrow:after,
.bs-popover-left>.arrow:after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1.0625rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #161c2d
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner:after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@keyframes b {
    to {
        transform: rotate(1turn)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: b .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes c {
    0% {
        transform: scale(0)
    }
    50% {
        opacity: 1
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: c .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.bg-primary {
    background-color: #165985 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #2653d4 !important;
}

.bg-secondary {
    background-color: #858796 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #6b6d7d !important;
}

.bg-success {
    background-color: #1cc88a !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #169b6b !important;
}

.bg-info {
    background-color: #36b9cc !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #2a96a5 !important;
}

.bg-warning {
    background-color: #f6c23e !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #f4b30d !important;
}

.bg-danger {
    background-color: #e74a3b !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #d52a1a !important;
}

.bg-light {
    background-color: #f8f9fc !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #d4daed !important;
}

.bg-dark {
    background-color: #5a5c69 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #42444e !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #f1f4f8!important
}

.border-top {
    border-top: 1px solid #f1f4f8!important
}

.border-right {
    border-right: 1px solid #f1f4f8!important
}

.border-bottom {
    border-bottom: 1px solid #f1f4f8!important
}

.border-left {
    border-left: 1px solid #f1f4f8!important
}

.border-0 {
    border: 0!important
}

.border-top-0 {
    border-top: 0!important
}

.border-right-0 {
    border-right: 0!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-left-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #165985!important
}

.border-secondary {
    border-color: #506690!important
}

.border-success {
    border-color: #42ba96!important
}

.border-info {
    border-color: #7c69ef!important
}

.border-warning {
    border-color: #fad776!important
}

.border-danger {
    border-color: #df4759!important
}

.border-light {
    border-color: #f9fbfd!important
}

.border-dark {
    border-color: #2b354f!important
}

.border-primary-desat {
    border-color: #6c8aec!important
}

.border-white {
    border-color: #fff!important
}

.rounded-sm {
    border-radius: .2rem!important
}

.rounded {
    border-radius: .375rem!important
}

.rounded-top {
    border-top-left-radius: .375rem!important
}

.rounded-right,
.rounded-top {
    border-top-right-radius: .375rem!important
}

.rounded-bottom,
.rounded-right {
    border-bottom-right-radius: .375rem!important
}

.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: .375rem!important
}

.rounded-left {
    border-top-left-radius: .375rem!important
}

.rounded-lg {
    border-radius: .5rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: 50rem!important
}

.rounded-0 {
    border-radius: 0!important
}

.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none!important
    }
    .d-sm-inline {
        display: inline!important
    }
    .d-sm-inline-block {
        display: inline-block!important
    }
    .d-sm-block {
        display: block!important
    }
    .d-sm-table {
        display: table!important
    }
    .d-sm-table-row {
        display: table-row!important
    }
    .d-sm-table-cell {
        display: table-cell!important
    }
    .d-sm-flex {
        display: flex!important
    }
    .d-sm-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none!important
    }
    .d-md-inline {
        display: inline!important
    }
    .d-md-inline-block {
        display: inline-block!important
    }
    .d-md-block {
        display: block!important
    }
    .d-md-table {
        display: table!important
    }
    .d-md-table-row {
        display: table-row!important
    }
    .d-md-table-cell {
        display: table-cell!important
    }
    .d-md-flex {
        display: flex!important
    }
    .d-md-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none!important
    }
    .d-lg-inline {
        display: inline!important
    }
    .d-lg-inline-block {
        display: inline-block!important
    }
    .d-lg-block {
        display: block!important
    }
    .d-lg-table {
        display: table!important
    }
    .d-lg-table-row {
        display: table-row!important
    }
    .d-lg-table-cell {
        display: table-cell!important
    }
    .d-lg-flex {
        display: flex!important
    }
    .d-lg-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none!important
    }
    .d-xl-inline {
        display: inline!important
    }
    .d-xl-inline-block {
        display: inline-block!important
    }
    .d-xl-block {
        display: block!important
    }
    .d-xl-table {
        display: table!important
    }
    .d-xl-table-row {
        display: table-row!important
    }
    .d-xl-table-cell {
        display: table-cell!important
    }
    .d-xl-flex {
        display: flex!important
    }
    .d-xl-inline-flex {
        display: inline-flex!important
    }
}

@media print {
    .d-print-none {
        display: none!important
    }
    .d-print-inline {
        display: inline!important
    }
    .d-print-inline-block {
        display: inline-block!important
    }
    .d-print-block {
        display: block!important
    }
    .d-print-table {
        display: table!important
    }
    .d-print-table-row {
        display: table-row!important
    }
    .d-print-table-cell {
        display: table-cell!important
    }
    .d-print-flex {
        display: flex!important
    }
    .d-print-inline-flex {
        display: inline-flex!important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive:before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9:before {
    padding-top: 42.85714%
}

.embed-responsive-16by9:before {
    padding-top: 56.25%
}

.embed-responsive-4by3:before {
    padding-top: 75%
}

.embed-responsive-1by1:before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.flex-fill {
    flex: 1 1 auto!important
}

.flex-grow-0 {
    flex-grow: 0!important
}

.flex-grow-1 {
    flex-grow: 1!important
}

.flex-shrink-0 {
    flex-shrink: 0!important
}

.flex-shrink-1 {
    flex-shrink: 1!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row!important
    }
    .flex-sm-column {
        flex-direction: column!important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-sm-wrap {
        flex-wrap: wrap!important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-sm-fill {
        flex: 1 1 auto!important
    }
    .flex-sm-grow-0 {
        flex-grow: 0!important
    }
    .flex-sm-grow-1 {
        flex-grow: 1!important
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-sm-start {
        justify-content: flex-start!important
    }
    .justify-content-sm-end {
        justify-content: flex-end!important
    }
    .justify-content-sm-center {
        justify-content: center!important
    }
    .justify-content-sm-between {
        justify-content: space-between!important
    }
    .justify-content-sm-around {
        justify-content: space-around!important
    }
    .align-items-sm-start {
        align-items: flex-start!important
    }
    .align-items-sm-end {
        align-items: flex-end!important
    }
    .align-items-sm-center {
        align-items: center!important
    }
    .align-items-sm-baseline {
        align-items: baseline!important
    }
    .align-items-sm-stretch {
        align-items: stretch!important
    }
    .align-content-sm-start {
        align-content: flex-start!important
    }
    .align-content-sm-end {
        align-content: flex-end!important
    }
    .align-content-sm-center {
        align-content: center!important
    }
    .align-content-sm-between {
        align-content: space-between!important
    }
    .align-content-sm-around {
        align-content: space-around!important
    }
    .align-content-sm-stretch {
        align-content: stretch!important
    }
    .align-self-sm-auto {
        align-self: auto!important
    }
    .align-self-sm-start {
        align-self: flex-start!important
    }
    .align-self-sm-end {
        align-self: flex-end!important
    }
    .align-self-sm-center {
        align-self: center!important
    }
    .align-self-sm-baseline {
        align-self: baseline!important
    }
    .align-self-sm-stretch {
        align-self: stretch!important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row!important
    }
    .flex-md-column {
        flex-direction: column!important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-md-wrap {
        flex-wrap: wrap!important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-md-fill {
        flex: 1 1 auto!important
    }
    .flex-md-grow-0 {
        flex-grow: 0!important
    }
    .flex-md-grow-1 {
        flex-grow: 1!important
    }
    .flex-md-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-md-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-md-start {
        justify-content: flex-start!important
    }
    .justify-content-md-end {
        justify-content: flex-end!important
    }
    .justify-content-md-center {
        justify-content: center!important
    }
    .justify-content-md-between {
        justify-content: space-between!important
    }
    .justify-content-md-around {
        justify-content: space-around!important
    }
    .align-items-md-start {
        align-items: flex-start!important
    }
    .align-items-md-end {
        align-items: flex-end!important
    }
    .align-items-md-center {
        align-items: center!important
    }
    .align-items-md-baseline {
        align-items: baseline!important
    }
    .align-items-md-stretch {
        align-items: stretch!important
    }
    .align-content-md-start {
        align-content: flex-start!important
    }
    .align-content-md-end {
        align-content: flex-end!important
    }
    .align-content-md-center {
        align-content: center!important
    }
    .align-content-md-between {
        align-content: space-between!important
    }
    .align-content-md-around {
        align-content: space-around!important
    }
    .align-content-md-stretch {
        align-content: stretch!important
    }
    .align-self-md-auto {
        align-self: auto!important
    }
    .align-self-md-start {
        align-self: flex-start!important
    }
    .align-self-md-end {
        align-self: flex-end!important
    }
    .align-self-md-center {
        align-self: center!important
    }
    .align-self-md-baseline {
        align-self: baseline!important
    }
    .align-self-md-stretch {
        align-self: stretch!important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row!important
    }
    .flex-lg-column {
        flex-direction: column!important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-lg-wrap {
        flex-wrap: wrap!important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-lg-fill {
        flex: 1 1 auto!important
    }
    .flex-lg-grow-0 {
        flex-grow: 0!important
    }
    .flex-lg-grow-1 {
        flex-grow: 1!important
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-lg-start {
        justify-content: flex-start!important
    }
    .justify-content-lg-end {
        justify-content: flex-end!important
    }
    .justify-content-lg-center {
        justify-content: center!important
    }
    .justify-content-lg-between {
        justify-content: space-between!important
    }
    .justify-content-lg-around {
        justify-content: space-around!important
    }
    .align-items-lg-start {
        align-items: flex-start!important
    }
    .align-items-lg-end {
        align-items: flex-end!important
    }
    .align-items-lg-center {
        align-items: center!important
    }
    .align-items-lg-baseline {
        align-items: baseline!important
    }
    .align-items-lg-stretch {
        align-items: stretch!important
    }
    .align-content-lg-start {
        align-content: flex-start!important
    }
    .align-content-lg-end {
        align-content: flex-end!important
    }
    .align-content-lg-center {
        align-content: center!important
    }
    .align-content-lg-between {
        align-content: space-between!important
    }
    .align-content-lg-around {
        align-content: space-around!important
    }
    .align-content-lg-stretch {
        align-content: stretch!important
    }
    .align-self-lg-auto {
        align-self: auto!important
    }
    .align-self-lg-start {
        align-self: flex-start!important
    }
    .align-self-lg-end {
        align-self: flex-end!important
    }
    .align-self-lg-center {
        align-self: center!important
    }
    .align-self-lg-baseline {
        align-self: baseline!important
    }
    .align-self-lg-stretch {
        align-self: stretch!important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row!important
    }
    .flex-xl-column {
        flex-direction: column!important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-xl-wrap {
        flex-wrap: wrap!important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .flex-xl-fill {
        flex: 1 1 auto!important
    }
    .flex-xl-grow-0 {
        flex-grow: 0!important
    }
    .flex-xl-grow-1 {
        flex-grow: 1!important
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1!important
    }
    .justify-content-xl-start {
        justify-content: flex-start!important
    }
    .justify-content-xl-end {
        justify-content: flex-end!important
    }
    .justify-content-xl-center {
        justify-content: center!important
    }
    .justify-content-xl-between {
        justify-content: space-between!important
    }
    .justify-content-xl-around {
        justify-content: space-around!important
    }
    .align-items-xl-start {
        align-items: flex-start!important
    }
    .align-items-xl-end {
        align-items: flex-end!important
    }
    .align-items-xl-center {
        align-items: center!important
    }
    .align-items-xl-baseline {
        align-items: baseline!important
    }
    .align-items-xl-stretch {
        align-items: stretch!important
    }
    .align-content-xl-start {
        align-content: flex-start!important
    }
    .align-content-xl-end {
        align-content: flex-end!important
    }
    .align-content-xl-center {
        align-content: center!important
    }
    .align-content-xl-between {
        align-content: space-between!important
    }
    .align-content-xl-around {
        align-content: space-around!important
    }
    .align-content-xl-stretch {
        align-content: stretch!important
    }
    .align-self-xl-auto {
        align-self: auto!important
    }
    .align-self-xl-start {
        align-self: flex-start!important
    }
    .align-self-xl-end {
        align-self: flex-end!important
    }
    .align-self-xl-center {
        align-self: center!important
    }
    .align-self-xl-baseline {
        align-self: baseline!important
    }
    .align-self-xl-stretch {
        align-self: stretch!important
    }
}

.float-left {
    float: left!important
}

.float-right {
    float: right!important
}

.float-none {
    float: none!important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left!important
    }
    .float-sm-right {
        float: right!important
    }
    .float-sm-none {
        float: none!important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left!important
    }
    .float-md-right {
        float: right!important
    }
    .float-md-none {
        float: none!important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left!important
    }
    .float-lg-right {
        float: right!important
    }
    .float-lg-none {
        float: none!important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left!important
    }
    .float-xl-right {
        float: right!important
    }
    .float-xl-none {
        float: none!important
    }
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: -webkit-sticky!important;
    position: sticky!important
}

.fixed-top {
    top: 0
}

.fixed-bottom,
.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075)!important
}

.shadow {
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1)!important
}

.shadow-lg {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .1)!important
}

.shadow-none {
    box-shadow: none!important
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.w-110 {
    width: 110%!important
}

.w-120 {
    width: 120%!important
}

.w-130 {
    width: 130%!important
}

.w-140 {
    width: 140%!important
}

.w-150 {
    width: 150%!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.h-110 {
    height: 110%!important
}

.h-120 {
    height: 120%!important
}

.h-130 {
    height: 130%!important
}

.h-140 {
    height: 140%!important
}

.h-150 {
    height: 150%!important
}

.mh-100 {
    max-height: 100%!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.vh-100 {
    height: 100vh!important
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
}

.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,
.my-1 {
    margin-top: .25rem!important
}

.mr-1,
.mx-1 {
    margin-right: .25rem!important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,
.my-2 {
    margin-top: .5rem!important
}

.mr-2,
.mx-2 {
    margin-right: .5rem!important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: .75rem!important
}

.mt-3,
.my-3 {
    margin-top: .75rem!important
}

.mr-3,
.mx-3 {
    margin-right: .75rem!important
}

.mb-3,
.my-3 {
    margin-bottom: .75rem!important
}

.ml-3,
.mx-3 {
    margin-left: .75rem!important
}

.m-4 {
    margin: 1rem!important
}

.mt-4,
.my-4 {
    margin-top: 1rem!important
}

.mr-4,
.mx-4 {
    margin-right: 1rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 1rem!important
}

.ml-4,
.mx-4 {
    margin-left: 1rem!important
}

.m-5 {
    margin: 1.5rem!important
}

.mt-5,
.my-5 {
    margin-top: 1.5rem!important
}

.mr-5,
.mx-5 {
    margin-right: 1.5rem!important
}

.mb-5,
.my-5 {
    margin-bottom: 1.5rem!important
}

.ml-5,
.mx-5 {
    margin-left: 1.5rem!important
}

.m-6 {
    margin: 2rem!important
}

.mt-6,
.my-6 {
    margin-top: 2rem!important
}

.mr-6,
.mx-6 {
    margin-right: 2rem!important
}

.mb-6,
.my-6 {
    margin-bottom: 2rem!important
}

.ml-6,
.mx-6 {
    margin-left: 2rem!important
}

.m-7 {
    margin: 2.5rem!important
}

.mt-7,
.my-7 {
    margin-top: 2.5rem!important
}

.mr-7,
.mx-7 {
    margin-right: 2.5rem!important
}

.mb-7,
.my-7 {
    margin-bottom: 2.5rem!important
}

.ml-7,
.mx-7 {
    margin-left: 2.5rem!important
}

.m-8 {
    margin: 3rem!important
}

.mt-8,
.my-8 {
    margin-top: 3rem!important
}

.mr-8,
.mx-8 {
    margin-right: 3rem!important
}

.mb-8,
.my-8 {
    margin-bottom: 3rem!important
}

.ml-8,
.mx-8 {
    margin-left: 3rem!important
}

.m-9 {
    margin: 4rem!important
}

.mt-9,
.my-9 {
    margin-top: 4rem!important
}

.mr-9,
.mx-9 {
    margin-right: 4rem!important
}

.mb-9,
.my-9 {
    margin-bottom: 4rem!important
}

.ml-9,
.mx-9 {
    margin-left: 4rem!important
}

.m-10 {
    margin: 5rem!important
}

.mt-10,
.my-10 {
    margin-top: 5rem!important
}

.mr-10,
.mx-10 {
    margin-right: 5rem!important
}

.mb-10,
.my-10 {
    margin-bottom: 5rem!important
}

.ml-10,
.mx-10 {
    margin-left: 5rem!important
}

.m-11 {
    margin: 6rem!important
}

.mt-11,
.my-11 {
    margin-top: 6rem!important
}

.mr-11,
.mx-11 {
    margin-right: 6rem!important
}

.mb-11,
.my-11 {
    margin-bottom: 6rem!important
}

.ml-11,
.mx-11 {
    margin-left: 6rem!important
}

.m-12 {
    margin: 8rem!important
}

.mt-12,
.my-12 {
    margin-top: 8rem!important
}

.mr-12,
.mx-12 {
    margin-right: 8rem!important
}

.mb-12,
.my-12 {
    margin-bottom: 8rem!important
}

.ml-12,
.mx-12 {
    margin-left: 8rem!important
}

.m-13 {
    margin: 10rem!important
}

.mt-13,
.my-13 {
    margin-top: 10rem!important
}

.mr-13,
.mx-13 {
    margin-right: 10rem!important
}

.mb-13,
.my-13 {
    margin-bottom: 10rem!important
}

.ml-13,
.mx-13 {
    margin-left: 10rem!important
}

.m-14 {
    margin: 12rem!important
}

.mt-14,
.my-14 {
    margin-top: 12rem!important
}

.mr-14,
.mx-14 {
    margin-right: 12rem!important
}

.mb-14,
.my-14 {
    margin-bottom: 12rem!important
}

.ml-14,
.mx-14 {
    margin-left: 12rem!important
}

.m-15 {
    margin: 16rem!important
}

.mt-15,
.my-15 {
    margin-top: 16rem!important
}

.mr-15,
.mx-15 {
    margin-right: 16rem!important
}

.mb-15,
.my-15 {
    margin-bottom: 16rem!important
}

.ml-15,
.mx-15 {
    margin-left: 16rem!important
}

.m-16 {
    margin: 25rem!important
}

.mt-16,
.my-16 {
    margin-top: 25rem!important
}

.mr-16,
.mx-16 {
    margin-right: 25rem!important
}

.mb-16,
.my-16 {
    margin-bottom: 25rem!important
}

.ml-16,
.mx-16 {
    margin-left: 25rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0,
.py-0 {
    padding-top: 0!important
}

.pr-0,
.px-0 {
    padding-right: 0!important
}

.pb-0,
.py-0 {
    padding-bottom: 0!important
}

.pl-0,
.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,
.py-1 {
    padding-top: .25rem!important
}

.pr-1,
.px-1 {
    padding-right: .25rem!important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,
.py-2 {
    padding-top: .5rem!important
}

.pr-2,
.px-2 {
    padding-right: .5rem!important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: .75rem!important
}

.pt-3,
.py-3 {
    padding-top: .75rem!important
}

.pr-3,
.px-3 {
    padding-right: .75rem!important
}

.pb-3,
.py-3 {
    padding-bottom: .75rem!important
}

.pl-3,
.px-3 {
    padding-left: .75rem!important
}

.p-4 {
    padding: 1rem!important
}

.pt-4,
.py-4 {
    padding-top: 1rem!important
}

.pr-4,
.px-4 {
    padding-right: 1rem!important
}

.pb-4,
.py-4 {
    padding-bottom: 1rem!important
}

.pl-4,
.px-4 {
    padding-left: 1rem!important
}

.p-5 {
    padding: 1.5rem!important
}

.pt-5,
.py-5 {
    padding-top: 1.5rem!important
}

.pr-5,
.px-5 {
    padding-right: 1.5rem!important
}

.pb-5,
.py-5 {
    padding-bottom: 1.5rem!important
}

.pl-5,
.px-5 {
    padding-left: 1.5rem!important
}

.p-6 {
    padding: 2rem!important
}

.pt-6,
.py-6 {
    padding-top: 2rem!important
}

.pr-6,
.px-6 {
    padding-right: 2rem!important
}

.pb-6,
.py-6 {
    padding-bottom: 2rem!important
}

.pl-6,
.px-6 {
    padding-left: 2rem!important
}

.p-7 {
    padding: 2.5rem!important
}

.pt-7,
.py-7 {
    padding-top: 2.5rem!important
}

.pr-7,
.px-7 {
    padding-right: 2.5rem!important
}

.pb-7,
.py-7 {
    padding-bottom: 2.5rem!important
}

.pl-7,
.px-7 {
    padding-left: 2.5rem!important
}

.p-8 {
    padding: 3rem!important
}

.pt-8,
.py-8 {
    padding-top: 3rem!important
}

.pr-8,
.px-8 {
    padding-right: 3rem!important
}

.pb-8,
.py-8 {
    padding-bottom: 3rem!important
}

.pl-8,
.px-8 {
    padding-left: 3rem!important
}

.p-9 {
    padding: 4rem!important
}

.pt-9,
.py-9 {
    padding-top: 4rem!important
}

.pr-9,
.px-9 {
    padding-right: 4rem!important
}

.pb-9,
.py-9 {
    padding-bottom: 4rem!important
}

.pl-9,
.px-9 {
    padding-left: 4rem!important
}

.p-10 {
    padding: 5rem!important
}

.pt-10,
.py-10 {
    padding-top: 5rem!important
}

.pr-10,
.px-10 {
    padding-right: 5rem!important
}

.pb-10,
.py-10 {
    padding-bottom: 5rem!important
}

.pl-10,
.px-10 {
    padding-left: 5rem!important
}

.p-11 {
    padding: 6rem!important
}

.pt-11,
.py-11 {
    padding-top: 6rem!important
}

.pr-11,
.px-11 {
    padding-right: 6rem!important
}

.pb-11,
.py-11 {
    padding-bottom: 6rem!important
}

.pl-11,
.px-11 {
    padding-left: 6rem!important
}

.p-12 {
    padding: 8rem!important
}

.pt-12,
.py-12 {
    padding-top: 8rem!important
}

.pr-12,
.px-12 {
    padding-right: 8rem!important
}

.pb-12,
.py-12 {
    padding-bottom: 8rem!important
}

.pl-12,
.px-12 {
    padding-left: 8rem!important
}

.p-13 {
    padding: 10rem!important
}

.pt-13,
.py-13 {
    padding-top: 10rem!important
}

.pr-13,
.px-13 {
    padding-right: 10rem!important
}

.pb-13,
.py-13 {
    padding-bottom: 10rem!important
}

.pl-13,
.px-13 {
    padding-left: 10rem!important
}

.p-14 {
    padding: 12rem!important
}

.pt-14,
.py-14 {
    padding-top: 12rem!important
}

.pr-14,
.px-14 {
    padding-right: 12rem!important
}

.pb-14,
.py-14 {
    padding-bottom: 12rem!important
}

.pl-14,
.px-14 {
    padding-left: 12rem!important
}

.p-15 {
    padding: 16rem!important
}

.pt-15,
.py-15 {
    padding-top: 16rem!important
}

.pr-15,
.px-15 {
    padding-right: 16rem!important
}

.pb-15,
.py-15 {
    padding-bottom: 16rem!important
}

.pl-15,
.px-15 {
    padding-left: 16rem!important
}

.p-16 {
    padding: 25rem!important
}

.pt-16,
.py-16 {
    padding-top: 25rem!important
}

.pr-16,
.px-16 {
    padding-right: 25rem!important
}

.pb-16,
.py-16 {
    padding-bottom: 25rem!important
}

.pl-16,
.px-16 {
    padding-left: 25rem!important
}

.m-n1 {
    margin: -.25rem!important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem!important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem!important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem!important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem!important
}

.m-n2 {
    margin: -.5rem!important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem!important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem!important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem!important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem!important
}

.m-n3 {
    margin: -.75rem!important
}

.mt-n3,
.my-n3 {
    margin-top: -.75rem!important
}

.mr-n3,
.mx-n3 {
    margin-right: -.75rem!important
}

.mb-n3,
.my-n3 {
    margin-bottom: -.75rem!important
}

.ml-n3,
.mx-n3 {
    margin-left: -.75rem!important
}

.m-n4 {
    margin: -1rem!important
}

.mt-n4,
.my-n4 {
    margin-top: -1rem!important
}

.mr-n4,
.mx-n4 {
    margin-right: -1rem!important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1rem!important
}

.ml-n4,
.mx-n4 {
    margin-left: -1rem!important
}

.m-n5 {
    margin: -1.5rem!important
}

.mt-n5,
.my-n5 {
    margin-top: -1.5rem!important
}

.mr-n5,
.mx-n5 {
    margin-right: -1.5rem!important
}

.mb-n5,
.my-n5 {
    margin-bottom: -1.5rem!important
}

.ml-n5,
.mx-n5 {
    margin-left: -1.5rem!important
}

.m-n6 {
    margin: -2rem!important
}

.mt-n6,
.my-n6 {
    margin-top: -2rem!important
}

.mr-n6,
.mx-n6 {
    margin-right: -2rem!important
}

.mb-n6,
.my-n6 {
    margin-bottom: -2rem!important
}

.ml-n6,
.mx-n6 {
    margin-left: -2rem!important
}

.m-n7 {
    margin: -2.5rem!important
}

.mt-n7,
.my-n7 {
    margin-top: -2.5rem!important
}

.mr-n7,
.mx-n7 {
    margin-right: -2.5rem!important
}

.mb-n7,
.my-n7 {
    margin-bottom: -2.5rem!important
}

.ml-n7,
.mx-n7 {
    margin-left: -2.5rem!important
}

.m-n8 {
    margin: -3rem!important
}

.mt-n8,
.my-n8 {
    margin-top: -3rem!important
}

.mr-n8,
.mx-n8 {
    margin-right: -3rem!important
}

.mb-n8,
.my-n8 {
    margin-bottom: -3rem!important
}

.ml-n8,
.mx-n8 {
    margin-left: -3rem!important
}

.m-n9 {
    margin: -4rem!important
}

.mt-n9,
.my-n9 {
    margin-top: -4rem!important
}

.mr-n9,
.mx-n9 {
    margin-right: -4rem!important
}

.mb-n9,
.my-n9 {
    margin-bottom: -4rem!important
}

.ml-n9,
.mx-n9 {
    margin-left: -4rem!important
}

.m-n10 {
    margin: -5rem!important
}

.mt-n10,
.my-n10 {
    margin-top: -5rem!important
}

.mr-n10,
.mx-n10 {
    margin-right: -5rem!important
}

.mb-n10,
.my-n10 {
    margin-bottom: -5rem!important
}

.ml-n10,
.mx-n10 {
    margin-left: -5rem!important
}

.m-n11 {
    margin: -6rem!important
}

.mt-n11,
.my-n11 {
    margin-top: -6rem!important
}

.mr-n11,
.mx-n11 {
    margin-right: -6rem!important
}

.mb-n11,
.my-n11 {
    margin-bottom: -6rem!important
}

.ml-n11,
.mx-n11 {
    margin-left: -6rem!important
}

.m-n12 {
    margin: -8rem!important
}

.mt-n12,
.my-n12 {
    margin-top: -8rem!important
}

.mr-n12,
.mx-n12 {
    margin-right: -8rem!important
}

.mb-n12,
.my-n12 {
    margin-bottom: -8rem!important
}

.ml-n12,
.mx-n12 {
    margin-left: -8rem!important
}

.m-n13 {
    margin: -10rem!important
}

.mt-n13,
.my-n13 {
    margin-top: -10rem!important
}

.mr-n13,
.mx-n13 {
    margin-right: -10rem!important
}

.mb-n13,
.my-n13 {
    margin-bottom: -10rem!important
}

.ml-n13,
.mx-n13 {
    margin-left: -10rem!important
}

.m-n14 {
    margin: -12rem!important
}

.mt-n14,
.my-n14 {
    margin-top: -12rem!important
}

.mr-n14,
.mx-n14 {
    margin-right: -12rem!important
}

.mb-n14,
.my-n14 {
    margin-bottom: -12rem!important
}

.ml-n14,
.mx-n14 {
    margin-left: -12rem!important
}

.m-n15 {
    margin: -16rem!important
}

.mt-n15,
.my-n15 {
    margin-top: -16rem!important
}

.mr-n15,
.mx-n15 {
    margin-right: -16rem!important
}

.mb-n15,
.my-n15 {
    margin-bottom: -16rem!important
}

.ml-n15,
.mx-n15 {
    margin-left: -16rem!important
}

.m-n16 {
    margin: -25rem!important
}

.mt-n16,
.my-n16 {
    margin-top: -25rem!important
}

.mr-n16,
.mx-n16 {
    margin-right: -25rem!important
}

.mb-n16,
.my-n16 {
    margin-bottom: -25rem!important
}

.ml-n16,
.mx-n16 {
    margin-left: -25rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,
.my-auto {
    margin-top: auto!important
}

.mr-auto,
.mx-auto {
    margin-right: auto!important
}

.mb-auto,
.my-auto {
    margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
    margin-left: auto!important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0!important
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0!important
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0!important
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0!important
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0!important
    }
    .m-sm-1 {
        margin: .25rem!important
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem!important
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem!important
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem!important
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem!important
    }
    .m-sm-2 {
        margin: .5rem!important
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem!important
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem!important
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem!important
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem!important
    }
    .m-sm-3 {
        margin: .75rem!important
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: .75rem!important
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: .75rem!important
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: .75rem!important
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: .75rem!important
    }
    .m-sm-4 {
        margin: 1rem!important
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1rem!important
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1rem!important
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1rem!important
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1rem!important
    }
    .m-sm-5 {
        margin: 1.5rem!important
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 1.5rem!important
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 1.5rem!important
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 1.5rem!important
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 1.5rem!important
    }
    .m-sm-6 {
        margin: 2rem!important
    }
    .mt-sm-6,
    .my-sm-6 {
        margin-top: 2rem!important
    }
    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 2rem!important
    }
    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 2rem!important
    }
    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 2rem!important
    }
    .m-sm-7 {
        margin: 2.5rem!important
    }
    .mt-sm-7,
    .my-sm-7 {
        margin-top: 2.5rem!important
    }
    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 2.5rem!important
    }
    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 2.5rem!important
    }
    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 2.5rem!important
    }
    .m-sm-8 {
        margin: 3rem!important
    }
    .mt-sm-8,
    .my-sm-8 {
        margin-top: 3rem!important
    }
    .mr-sm-8,
    .mx-sm-8 {
        margin-right: 3rem!important
    }
    .mb-sm-8,
    .my-sm-8 {
        margin-bottom: 3rem!important
    }
    .ml-sm-8,
    .mx-sm-8 {
        margin-left: 3rem!important
    }
    .m-sm-9 {
        margin: 4rem!important
    }
    .mt-sm-9,
    .my-sm-9 {
        margin-top: 4rem!important
    }
    .mr-sm-9,
    .mx-sm-9 {
        margin-right: 4rem!important
    }
    .mb-sm-9,
    .my-sm-9 {
        margin-bottom: 4rem!important
    }
    .ml-sm-9,
    .mx-sm-9 {
        margin-left: 4rem!important
    }
    .m-sm-10 {
        margin: 5rem!important
    }
    .mt-sm-10,
    .my-sm-10 {
        margin-top: 5rem!important
    }
    .mr-sm-10,
    .mx-sm-10 {
        margin-right: 5rem!important
    }
    .mb-sm-10,
    .my-sm-10 {
        margin-bottom: 5rem!important
    }
    .ml-sm-10,
    .mx-sm-10 {
        margin-left: 5rem!important
    }
    .m-sm-11 {
        margin: 6rem!important
    }
    .mt-sm-11,
    .my-sm-11 {
        margin-top: 6rem!important
    }
    .mr-sm-11,
    .mx-sm-11 {
        margin-right: 6rem!important
    }
    .mb-sm-11,
    .my-sm-11 {
        margin-bottom: 6rem!important
    }
    .ml-sm-11,
    .mx-sm-11 {
        margin-left: 6rem!important
    }
    .m-sm-12 {
        margin: 8rem!important
    }
    .mt-sm-12,
    .my-sm-12 {
        margin-top: 8rem!important
    }
    .mr-sm-12,
    .mx-sm-12 {
        margin-right: 8rem!important
    }
    .mb-sm-12,
    .my-sm-12 {
        margin-bottom: 8rem!important
    }
    .ml-sm-12,
    .mx-sm-12 {
        margin-left: 8rem!important
    }
    .m-sm-13 {
        margin: 10rem!important
    }
    .mt-sm-13,
    .my-sm-13 {
        margin-top: 10rem!important
    }
    .mr-sm-13,
    .mx-sm-13 {
        margin-right: 10rem!important
    }
    .mb-sm-13,
    .my-sm-13 {
        margin-bottom: 10rem!important
    }
    .ml-sm-13,
    .mx-sm-13 {
        margin-left: 10rem!important
    }
    .m-sm-14 {
        margin: 12rem!important
    }
    .mt-sm-14,
    .my-sm-14 {
        margin-top: 12rem!important
    }
    .mr-sm-14,
    .mx-sm-14 {
        margin-right: 12rem!important
    }
    .mb-sm-14,
    .my-sm-14 {
        margin-bottom: 12rem!important
    }
    .ml-sm-14,
    .mx-sm-14 {
        margin-left: 12rem!important
    }
    .m-sm-15 {
        margin: 16rem!important
    }
    .mt-sm-15,
    .my-sm-15 {
        margin-top: 16rem!important
    }
    .mr-sm-15,
    .mx-sm-15 {
        margin-right: 16rem!important
    }
    .mb-sm-15,
    .my-sm-15 {
        margin-bottom: 16rem!important
    }
    .ml-sm-15,
    .mx-sm-15 {
        margin-left: 16rem!important
    }
    .m-sm-16 {
        margin: 25rem!important
    }
    .mt-sm-16,
    .my-sm-16 {
        margin-top: 25rem!important
    }
    .mr-sm-16,
    .mx-sm-16 {
        margin-right: 25rem!important
    }
    .mb-sm-16,
    .my-sm-16 {
        margin-bottom: 25rem!important
    }
    .ml-sm-16,
    .mx-sm-16 {
        margin-left: 25rem!important
    }
    .p-sm-0 {
        padding: 0!important
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0!important
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0!important
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0!important
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0!important
    }
    .p-sm-1 {
        padding: .25rem!important
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem!important
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem!important
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem!important
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem!important
    }
    .p-sm-2 {
        padding: .5rem!important
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem!important
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem!important
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem!important
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem!important
    }
    .p-sm-3 {
        padding: .75rem!important
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: .75rem!important
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: .75rem!important
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: .75rem!important
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: .75rem!important
    }
    .p-sm-4 {
        padding: 1rem!important
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1rem!important
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1rem!important
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1rem!important
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1rem!important
    }
    .p-sm-5 {
        padding: 1.5rem!important
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 1.5rem!important
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 1.5rem!important
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 1.5rem!important
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 1.5rem!important
    }
    .p-sm-6 {
        padding: 2rem!important
    }
    .pt-sm-6,
    .py-sm-6 {
        padding-top: 2rem!important
    }
    .pr-sm-6,
    .px-sm-6 {
        padding-right: 2rem!important
    }
    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 2rem!important
    }
    .pl-sm-6,
    .px-sm-6 {
        padding-left: 2rem!important
    }
    .p-sm-7 {
        padding: 2.5rem!important
    }
    .pt-sm-7,
    .py-sm-7 {
        padding-top: 2.5rem!important
    }
    .pr-sm-7,
    .px-sm-7 {
        padding-right: 2.5rem!important
    }
    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 2.5rem!important
    }
    .pl-sm-7,
    .px-sm-7 {
        padding-left: 2.5rem!important
    }
    .p-sm-8 {
        padding: 3rem!important
    }
    .pt-sm-8,
    .py-sm-8 {
        padding-top: 3rem!important
    }
    .pr-sm-8,
    .px-sm-8 {
        padding-right: 3rem!important
    }
    .pb-sm-8,
    .py-sm-8 {
        padding-bottom: 3rem!important
    }
    .pl-sm-8,
    .px-sm-8 {
        padding-left: 3rem!important
    }
    .p-sm-9 {
        padding: 4rem!important
    }
    .pt-sm-9,
    .py-sm-9 {
        padding-top: 4rem!important
    }
    .pr-sm-9,
    .px-sm-9 {
        padding-right: 4rem!important
    }
    .pb-sm-9,
    .py-sm-9 {
        padding-bottom: 4rem!important
    }
    .pl-sm-9,
    .px-sm-9 {
        padding-left: 4rem!important
    }
    .p-sm-10 {
        padding: 5rem!important
    }
    .pt-sm-10,
    .py-sm-10 {
        padding-top: 5rem!important
    }
    .pr-sm-10,
    .px-sm-10 {
        padding-right: 5rem!important
    }
    .pb-sm-10,
    .py-sm-10 {
        padding-bottom: 5rem!important
    }
    .pl-sm-10,
    .px-sm-10 {
        padding-left: 5rem!important
    }
    .p-sm-11 {
        padding: 6rem!important
    }
    .pt-sm-11,
    .py-sm-11 {
        padding-top: 6rem!important
    }
    .pr-sm-11,
    .px-sm-11 {
        padding-right: 6rem!important
    }
    .pb-sm-11,
    .py-sm-11 {
        padding-bottom: 6rem!important
    }
    .pl-sm-11,
    .px-sm-11 {
        padding-left: 6rem!important
    }
    .p-sm-12 {
        padding: 8rem!important
    }
    .pt-sm-12,
    .py-sm-12 {
        padding-top: 8rem!important
    }
    .pr-sm-12,
    .px-sm-12 {
        padding-right: 8rem!important
    }
    .pb-sm-12,
    .py-sm-12 {
        padding-bottom: 8rem!important
    }
    .pl-sm-12,
    .px-sm-12 {
        padding-left: 8rem!important
    }
    .p-sm-13 {
        padding: 10rem!important
    }
    .pt-sm-13,
    .py-sm-13 {
        padding-top: 10rem!important
    }
    .pr-sm-13,
    .px-sm-13 {
        padding-right: 10rem!important
    }
    .pb-sm-13,
    .py-sm-13 {
        padding-bottom: 10rem!important
    }
    .pl-sm-13,
    .px-sm-13 {
        padding-left: 10rem!important
    }
    .p-sm-14 {
        padding: 12rem!important
    }
    .pt-sm-14,
    .py-sm-14 {
        padding-top: 12rem!important
    }
    .pr-sm-14,
    .px-sm-14 {
        padding-right: 12rem!important
    }
    .pb-sm-14,
    .py-sm-14 {
        padding-bottom: 12rem!important
    }
    .pl-sm-14,
    .px-sm-14 {
        padding-left: 12rem!important
    }
    .p-sm-15 {
        padding: 16rem!important
    }
    .pt-sm-15,
    .py-sm-15 {
        padding-top: 16rem!important
    }
    .pr-sm-15,
    .px-sm-15 {
        padding-right: 16rem!important
    }
    .pb-sm-15,
    .py-sm-15 {
        padding-bottom: 16rem!important
    }
    .pl-sm-15,
    .px-sm-15 {
        padding-left: 16rem!important
    }
    .p-sm-16 {
        padding: 25rem!important
    }
    .pt-sm-16,
    .py-sm-16 {
        padding-top: 25rem!important
    }
    .pr-sm-16,
    .px-sm-16 {
        padding-right: 25rem!important
    }
    .pb-sm-16,
    .py-sm-16 {
        padding-bottom: 25rem!important
    }
    .pl-sm-16,
    .px-sm-16 {
        padding-left: 25rem!important
    }
    .m-sm-n1 {
        margin: -.25rem!important
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem!important
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem!important
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem!important
    }
    .m-sm-n2 {
        margin: -.5rem!important
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem!important
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem!important
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem!important
    }
    .m-sm-n3 {
        margin: -.75rem!important
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -.75rem!important
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -.75rem!important
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -.75rem!important
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -.75rem!important
    }
    .m-sm-n4 {
        margin: -1rem!important
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1rem!important
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1rem!important
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1rem!important
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1rem!important
    }
    .m-sm-n5 {
        margin: -1.5rem!important
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -1.5rem!important
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -1.5rem!important
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -1.5rem!important
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -1.5rem!important
    }
    .m-sm-n6 {
        margin: -2rem!important
    }
    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -2rem!important
    }
    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -2rem!important
    }
    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -2rem!important
    }
    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -2rem!important
    }
    .m-sm-n7 {
        margin: -2.5rem!important
    }
    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -2.5rem!important
    }
    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -2.5rem!important
    }
    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -2.5rem!important
    }
    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -2.5rem!important
    }
    .m-sm-n8 {
        margin: -3rem!important
    }
    .mt-sm-n8,
    .my-sm-n8 {
        margin-top: -3rem!important
    }
    .mr-sm-n8,
    .mx-sm-n8 {
        margin-right: -3rem!important
    }
    .mb-sm-n8,
    .my-sm-n8 {
        margin-bottom: -3rem!important
    }
    .ml-sm-n8,
    .mx-sm-n8 {
        margin-left: -3rem!important
    }
    .m-sm-n9 {
        margin: -4rem!important
    }
    .mt-sm-n9,
    .my-sm-n9 {
        margin-top: -4rem!important
    }
    .mr-sm-n9,
    .mx-sm-n9 {
        margin-right: -4rem!important
    }
    .mb-sm-n9,
    .my-sm-n9 {
        margin-bottom: -4rem!important
    }
    .ml-sm-n9,
    .mx-sm-n9 {
        margin-left: -4rem!important
    }
    .m-sm-n10 {
        margin: -5rem!important
    }
    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -5rem!important
    }
    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -5rem!important
    }
    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -5rem!important
    }
    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -5rem!important
    }
    .m-sm-n11 {
        margin: -6rem!important
    }
    .mt-sm-n11,
    .my-sm-n11 {
        margin-top: -6rem!important
    }
    .mr-sm-n11,
    .mx-sm-n11 {
        margin-right: -6rem!important
    }
    .mb-sm-n11,
    .my-sm-n11 {
        margin-bottom: -6rem!important
    }
    .ml-sm-n11,
    .mx-sm-n11 {
        margin-left: -6rem!important
    }
    .m-sm-n12 {
        margin: -8rem!important
    }
    .mt-sm-n12,
    .my-sm-n12 {
        margin-top: -8rem!important
    }
    .mr-sm-n12,
    .mx-sm-n12 {
        margin-right: -8rem!important
    }
    .mb-sm-n12,
    .my-sm-n12 {
        margin-bottom: -8rem!important
    }
    .ml-sm-n12,
    .mx-sm-n12 {
        margin-left: -8rem!important
    }
    .m-sm-n13 {
        margin: -10rem!important
    }
    .mt-sm-n13,
    .my-sm-n13 {
        margin-top: -10rem!important
    }
    .mr-sm-n13,
    .mx-sm-n13 {
        margin-right: -10rem!important
    }
    .mb-sm-n13,
    .my-sm-n13 {
        margin-bottom: -10rem!important
    }
    .ml-sm-n13,
    .mx-sm-n13 {
        margin-left: -10rem!important
    }
    .m-sm-n14 {
        margin: -12rem!important
    }
    .mt-sm-n14,
    .my-sm-n14 {
        margin-top: -12rem!important
    }
    .mr-sm-n14,
    .mx-sm-n14 {
        margin-right: -12rem!important
    }
    .mb-sm-n14,
    .my-sm-n14 {
        margin-bottom: -12rem!important
    }
    .ml-sm-n14,
    .mx-sm-n14 {
        margin-left: -12rem!important
    }
    .m-sm-n15 {
        margin: -16rem!important
    }
    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -16rem!important
    }
    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -16rem!important
    }
    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -16rem!important
    }
    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -16rem!important
    }
    .m-sm-n16 {
        margin: -25rem!important
    }
    .mt-sm-n16,
    .my-sm-n16 {
        margin-top: -25rem!important
    }
    .mr-sm-n16,
    .mx-sm-n16 {
        margin-right: -25rem!important
    }
    .mb-sm-n16,
    .my-sm-n16 {
        margin-bottom: -25rem!important
    }
    .ml-sm-n16,
    .mx-sm-n16 {
        margin-left: -25rem!important
    }
    .m-sm-auto {
        margin: auto!important
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto!important
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto!important
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto!important
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto!important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0!important
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0!important
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0!important
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0!important
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0!important
    }
    .m-md-1 {
        margin: .25rem!important
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem!important
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem!important
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem!important
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem!important
    }
    .m-md-2 {
        margin: .5rem!important
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem!important
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem!important
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem!important
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem!important
    }
    .m-md-3 {
        margin: .75rem!important
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: .75rem!important
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: .75rem!important
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: .75rem!important
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: .75rem!important
    }
    .m-md-4 {
        margin: 1rem!important
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1rem!important
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1rem!important
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1rem!important
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1rem!important
    }
    .m-md-5 {
        margin: 1.5rem!important
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 1.5rem!important
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 1.5rem!important
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 1.5rem!important
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 1.5rem!important
    }
    .m-md-6 {
        margin: 2rem!important
    }
    .mt-md-6,
    .my-md-6 {
        margin-top: 2rem!important
    }
    .mr-md-6,
    .mx-md-6 {
        margin-right: 2rem!important
    }
    .mb-md-6,
    .my-md-6 {
        margin-bottom: 2rem!important
    }
    .ml-md-6,
    .mx-md-6 {
        margin-left: 2rem!important
    }
    .m-md-7 {
        margin: 2.5rem!important
    }
    .mt-md-7,
    .my-md-7 {
        margin-top: 2.5rem!important
    }
    .mr-md-7,
    .mx-md-7 {
        margin-right: 2.5rem!important
    }
    .mb-md-7,
    .my-md-7 {
        margin-bottom: 2.5rem!important
    }
    .ml-md-7,
    .mx-md-7 {
        margin-left: 2.5rem!important
    }
    .m-md-8 {
        margin: 3rem!important
    }
    .mt-md-8,
    .my-md-8 {
        margin-top: 3rem!important
    }
    .mr-md-8,
    .mx-md-8 {
        margin-right: 3rem!important
    }
    .mb-md-8,
    .my-md-8 {
        margin-bottom: 3rem!important
    }
    .ml-md-8,
    .mx-md-8 {
        margin-left: 3rem!important
    }
    .m-md-9 {
        margin: 4rem!important
    }
    .mt-md-9,
    .my-md-9 {
        margin-top: 4rem!important
    }
    .mr-md-9,
    .mx-md-9 {
        margin-right: 4rem!important
    }
    .mb-md-9,
    .my-md-9 {
        margin-bottom: 4rem!important
    }
    .ml-md-9,
    .mx-md-9 {
        margin-left: 4rem!important
    }
    .m-md-10 {
        margin: 5rem!important
    }
    .mt-md-10,
    .my-md-10 {
        margin-top: 5rem!important
    }
    .mr-md-10,
    .mx-md-10 {
        margin-right: 5rem!important
    }
    .mb-md-10,
    .my-md-10 {
        margin-bottom: 5rem!important
    }
    .ml-md-10,
    .mx-md-10 {
        margin-left: 5rem!important
    }
    .m-md-11 {
        margin: 6rem!important
    }
    .mt-md-11,
    .my-md-11 {
        margin-top: 6rem!important
    }
    .mr-md-11,
    .mx-md-11 {
        margin-right: 6rem!important
    }
    .mb-md-11,
    .my-md-11 {
        margin-bottom: 6rem!important
    }
    .ml-md-11,
    .mx-md-11 {
        margin-left: 6rem!important
    }
    .m-md-12 {
        margin: 8rem!important
    }
    .mt-md-12,
    .my-md-12 {
        margin-top: 8rem!important
    }
    .mr-md-12,
    .mx-md-12 {
        margin-right: 8rem!important
    }
    .mb-md-12,
    .my-md-12 {
        margin-bottom: 8rem!important
    }
    .ml-md-12,
    .mx-md-12 {
        margin-left: 8rem!important
    }
    .m-md-13 {
        margin: 10rem!important
    }
    .mt-md-13,
    .my-md-13 {
        margin-top: 10rem!important
    }
    .mr-md-13,
    .mx-md-13 {
        margin-right: 10rem!important
    }
    .mb-md-13,
    .my-md-13 {
        margin-bottom: 10rem!important
    }
    .ml-md-13,
    .mx-md-13 {
        margin-left: 10rem!important
    }
    .m-md-14 {
        margin: 12rem!important
    }
    .mt-md-14,
    .my-md-14 {
        margin-top: 12rem!important
    }
    .mr-md-14,
    .mx-md-14 {
        margin-right: 12rem!important
    }
    .mb-md-14,
    .my-md-14 {
        margin-bottom: 12rem!important
    }
    .ml-md-14,
    .mx-md-14 {
        margin-left: 12rem!important
    }
    .m-md-15 {
        margin: 16rem!important
    }
    .mt-md-15,
    .my-md-15 {
        margin-top: 16rem!important
    }
    .mr-md-15,
    .mx-md-15 {
        margin-right: 16rem!important
    }
    .mb-md-15,
    .my-md-15 {
        margin-bottom: 16rem!important
    }
    .ml-md-15,
    .mx-md-15 {
        margin-left: 16rem!important
    }
    .m-md-16 {
        margin: 25rem!important
    }
    .mt-md-16,
    .my-md-16 {
        margin-top: 25rem!important
    }
    .mr-md-16,
    .mx-md-16 {
        margin-right: 25rem!important
    }
    .mb-md-16,
    .my-md-16 {
        margin-bottom: 25rem!important
    }
    .ml-md-16,
    .mx-md-16 {
        margin-left: 25rem!important
    }
    .p-md-0 {
        padding: 0!important
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0!important
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0!important
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0!important
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0!important
    }
    .p-md-1 {
        padding: .25rem!important
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem!important
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem!important
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem!important
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem!important
    }
    .p-md-2 {
        padding: .5rem!important
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem!important
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem!important
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem!important
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem!important
    }
    .p-md-3 {
        padding: .75rem!important
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: .75rem!important
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: .75rem!important
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: .75rem!important
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: .75rem!important
    }
    .p-md-4 {
        padding: 1rem!important
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1rem!important
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1rem!important
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1rem!important
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1rem!important
    }
    .p-md-5 {
        padding: 1.5rem!important
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 1.5rem!important
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 1.5rem!important
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 1.5rem!important
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 1.5rem!important
    }
    .p-md-6 {
        padding: 2rem!important
    }
    .pt-md-6,
    .py-md-6 {
        padding-top: 2rem!important
    }
    .pr-md-6,
    .px-md-6 {
        padding-right: 2rem!important
    }
    .pb-md-6,
    .py-md-6 {
        padding-bottom: 2rem!important
    }
    .pl-md-6,
    .px-md-6 {
        padding-left: 2rem!important
    }
    .p-md-7 {
        padding: 2.5rem!important
    }
    .pt-md-7,
    .py-md-7 {
        padding-top: 2.5rem!important
    }
    .pr-md-7,
    .px-md-7 {
        padding-right: 2.5rem!important
    }
    .pb-md-7,
    .py-md-7 {
        padding-bottom: 2.5rem!important
    }
    .pl-md-7,
    .px-md-7 {
        padding-left: 2.5rem!important
    }
    .p-md-8 {
        padding: 3rem!important
    }
    .pt-md-8,
    .py-md-8 {
        padding-top: 3rem!important
    }
    .pr-md-8,
    .px-md-8 {
        padding-right: 3rem!important
    }
    .pb-md-8,
    .py-md-8 {
        padding-bottom: 3rem!important
    }
    .pl-md-8,
    .px-md-8 {
        padding-left: 3rem!important
    }
    .p-md-9 {
        padding: 4rem!important
    }
    .pt-md-9,
    .py-md-9 {
        padding-top: 4rem!important
    }
    .pr-md-9,
    .px-md-9 {
        padding-right: 4rem!important
    }
    .pb-md-9,
    .py-md-9 {
        padding-bottom: 4rem!important
    }
    .pl-md-9,
    .px-md-9 {
        padding-left: 4rem!important
    }
    .p-md-10 {
        padding: 5rem!important
    }
    .pt-md-10,
    .py-md-10 {
        padding-top: 5rem!important
    }
    .pr-md-10,
    .px-md-10 {
        padding-right: 5rem!important
    }
    .pb-md-10,
    .py-md-10 {
        padding-bottom: 5rem!important
    }
    .pl-md-10,
    .px-md-10 {
        padding-left: 5rem!important
    }
    .p-md-11 {
        padding: 6rem!important
    }
    .pt-md-11,
    .py-md-11 {
        padding-top: 6rem!important
    }
    .pr-md-11,
    .px-md-11 {
        padding-right: 6rem!important
    }
    .pb-md-11,
    .py-md-11 {
        padding-bottom: 6rem!important
    }
    .pl-md-11,
    .px-md-11 {
        padding-left: 6rem!important
    }
    .p-md-12 {
        padding: 8rem!important
    }
    .pt-md-12,
    .py-md-12 {
        padding-top: 8rem!important
    }
    .pr-md-12,
    .px-md-12 {
        padding-right: 8rem!important
    }
    .pb-md-12,
    .py-md-12 {
        padding-bottom: 8rem!important
    }
    .pl-md-12,
    .px-md-12 {
        padding-left: 8rem!important
    }
    .p-md-13 {
        padding: 10rem!important
    }
    .pt-md-13,
    .py-md-13 {
        padding-top: 10rem!important
    }
    .pr-md-13,
    .px-md-13 {
        padding-right: 10rem!important
    }
    .pb-md-13,
    .py-md-13 {
        padding-bottom: 10rem!important
    }
    .pl-md-13,
    .px-md-13 {
        padding-left: 10rem!important
    }
    .p-md-14 {
        padding: 12rem!important
    }
    .pt-md-14,
    .py-md-14 {
        padding-top: 12rem!important
    }
    .pr-md-14,
    .px-md-14 {
        padding-right: 12rem!important
    }
    .pb-md-14,
    .py-md-14 {
        padding-bottom: 12rem!important
    }
    .pl-md-14,
    .px-md-14 {
        padding-left: 12rem!important
    }
    .p-md-15 {
        padding: 16rem!important
    }
    .pt-md-15,
    .py-md-15 {
        padding-top: 16rem!important
    }
    .pr-md-15,
    .px-md-15 {
        padding-right: 16rem!important
    }
    .pb-md-15,
    .py-md-15 {
        padding-bottom: 16rem!important
    }
    .pl-md-15,
    .px-md-15 {
        padding-left: 16rem!important
    }
    .p-md-16 {
        padding: 25rem!important
    }
    .pt-md-16,
    .py-md-16 {
        padding-top: 25rem!important
    }
    .pr-md-16,
    .px-md-16 {
        padding-right: 25rem!important
    }
    .pb-md-16,
    .py-md-16 {
        padding-bottom: 25rem!important
    }
    .pl-md-16,
    .px-md-16 {
        padding-left: 25rem!important
    }
    .m-md-n1 {
        margin: -.25rem!important
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem!important
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem!important
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem!important
    }
    .m-md-n2 {
        margin: -.5rem!important
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem!important
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem!important
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem!important
    }
    .m-md-n3 {
        margin: -.75rem!important
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -.75rem!important
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -.75rem!important
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -.75rem!important
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -.75rem!important
    }
    .m-md-n4 {
        margin: -1rem!important
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1rem!important
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1rem!important
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1rem!important
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1rem!important
    }
    .m-md-n5 {
        margin: -1.5rem!important
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -1.5rem!important
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -1.5rem!important
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -1.5rem!important
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -1.5rem!important
    }
    .m-md-n6 {
        margin: -2rem!important
    }
    .mt-md-n6,
    .my-md-n6 {
        margin-top: -2rem!important
    }
    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -2rem!important
    }
    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -2rem!important
    }
    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -2rem!important
    }
    .m-md-n7 {
        margin: -2.5rem!important
    }
    .mt-md-n7,
    .my-md-n7 {
        margin-top: -2.5rem!important
    }
    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -2.5rem!important
    }
    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -2.5rem!important
    }
    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -2.5rem!important
    }
    .m-md-n8 {
        margin: -3rem!important
    }
    .mt-md-n8,
    .my-md-n8 {
        margin-top: -3rem!important
    }
    .mr-md-n8,
    .mx-md-n8 {
        margin-right: -3rem!important
    }
    .mb-md-n8,
    .my-md-n8 {
        margin-bottom: -3rem!important
    }
    .ml-md-n8,
    .mx-md-n8 {
        margin-left: -3rem!important
    }
    .m-md-n9 {
        margin: -4rem!important
    }
    .mt-md-n9,
    .my-md-n9 {
        margin-top: -4rem!important
    }
    .mr-md-n9,
    .mx-md-n9 {
        margin-right: -4rem!important
    }
    .mb-md-n9,
    .my-md-n9 {
        margin-bottom: -4rem!important
    }
    .ml-md-n9,
    .mx-md-n9 {
        margin-left: -4rem!important
    }
    .m-md-n10 {
        margin: -5rem!important
    }
    .mt-md-n10,
    .my-md-n10 {
        margin-top: -5rem!important
    }
    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -5rem!important
    }
    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -5rem!important
    }
    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -5rem!important
    }
    .m-md-n11 {
        margin: -6rem!important
    }
    .mt-md-n11,
    .my-md-n11 {
        margin-top: -6rem!important
    }
    .mr-md-n11,
    .mx-md-n11 {
        margin-right: -6rem!important
    }
    .mb-md-n11,
    .my-md-n11 {
        margin-bottom: -6rem!important
    }
    .ml-md-n11,
    .mx-md-n11 {
        margin-left: -6rem!important
    }
    .m-md-n12 {
        margin: -8rem!important
    }
    .mt-md-n12,
    .my-md-n12 {
        margin-top: -8rem!important
    }
    .mr-md-n12,
    .mx-md-n12 {
        margin-right: -8rem!important
    }
    .mb-md-n12,
    .my-md-n12 {
        margin-bottom: -8rem!important
    }
    .ml-md-n12,
    .mx-md-n12 {
        margin-left: -8rem!important
    }
    .m-md-n13 {
        margin: -10rem!important
    }
    .mt-md-n13,
    .my-md-n13 {
        margin-top: -10rem!important
    }
    .mr-md-n13,
    .mx-md-n13 {
        margin-right: -10rem!important
    }
    .mb-md-n13,
    .my-md-n13 {
        margin-bottom: -10rem!important
    }
    .ml-md-n13,
    .mx-md-n13 {
        margin-left: -10rem!important
    }
    .m-md-n14 {
        margin: -12rem!important
    }
    .mt-md-n14,
    .my-md-n14 {
        margin-top: -12rem!important
    }
    .mr-md-n14,
    .mx-md-n14 {
        margin-right: -12rem!important
    }
    .mb-md-n14,
    .my-md-n14 {
        margin-bottom: -12rem!important
    }
    .ml-md-n14,
    .mx-md-n14 {
        margin-left: -12rem!important
    }
    .m-md-n15 {
        margin: -16rem!important
    }
    .mt-md-n15,
    .my-md-n15 {
        margin-top: -16rem!important
    }
    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -16rem!important
    }
    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -16rem!important
    }
    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -16rem!important
    }
    .m-md-n16 {
        margin: -25rem!important
    }
    .mt-md-n16,
    .my-md-n16 {
        margin-top: -25rem!important
    }
    .mr-md-n16,
    .mx-md-n16 {
        margin-right: -25rem!important
    }
    .mb-md-n16,
    .my-md-n16 {
        margin-bottom: -25rem!important
    }
    .ml-md-n16,
    .mx-md-n16 {
        margin-left: -25rem!important
    }
    .m-md-auto {
        margin: auto!important
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto!important
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto!important
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto!important
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto!important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0!important
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0!important
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0!important
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0!important
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0!important
    }
    .m-lg-1 {
        margin: .25rem!important
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem!important
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem!important
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem!important
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem!important
    }
    .m-lg-2 {
        margin: .5rem!important
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem!important
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem!important
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem!important
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem!important
    }
    .m-lg-3 {
        margin: .75rem!important
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: .75rem!important
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: .75rem!important
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: .75rem!important
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: .75rem!important
    }
    .m-lg-4 {
        margin: 1rem!important
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1rem!important
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1rem!important
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1rem!important
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1rem!important
    }
    .m-lg-5 {
        margin: 1.5rem!important
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 1.5rem!important
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 1.5rem!important
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 1.5rem!important
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 1.5rem!important
    }
    .m-lg-6 {
        margin: 2rem!important
    }
    .mt-lg-6,
    .my-lg-6 {
        margin-top: 2rem!important
    }
    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 2rem!important
    }
    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 2rem!important
    }
    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 2rem!important
    }
    .m-lg-7 {
        margin: 2.5rem!important
    }
    .mt-lg-7,
    .my-lg-7 {
        margin-top: 2.5rem!important
    }
    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 2.5rem!important
    }
    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 2.5rem!important
    }
    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 2.5rem!important
    }
    .m-lg-8 {
        margin: 3rem!important
    }
    .mt-lg-8,
    .my-lg-8 {
        margin-top: 3rem!important
    }
    .mr-lg-8,
    .mx-lg-8 {
        margin-right: 3rem!important
    }
    .mb-lg-8,
    .my-lg-8 {
        margin-bottom: 3rem!important
    }
    .ml-lg-8,
    .mx-lg-8 {
        margin-left: 3rem!important
    }
    .m-lg-9 {
        margin: 4rem!important
    }
    .mt-lg-9,
    .my-lg-9 {
        margin-top: 4rem!important
    }
    .mr-lg-9,
    .mx-lg-9 {
        margin-right: 4rem!important
    }
    .mb-lg-9,
    .my-lg-9 {
        margin-bottom: 4rem!important
    }
    .ml-lg-9,
    .mx-lg-9 {
        margin-left: 4rem!important
    }
    .m-lg-10 {
        margin: 5rem!important
    }
    .mt-lg-10,
    .my-lg-10 {
        margin-top: 5rem!important
    }
    .mr-lg-10,
    .mx-lg-10 {
        margin-right: 5rem!important
    }
    .mb-lg-10,
    .my-lg-10 {
        margin-bottom: 5rem!important
    }
    .ml-lg-10,
    .mx-lg-10 {
        margin-left: 5rem!important
    }
    .m-lg-11 {
        margin: 6rem!important
    }
    .mt-lg-11,
    .my-lg-11 {
        margin-top: 6rem!important
    }
    .mr-lg-11,
    .mx-lg-11 {
        margin-right: 6rem!important
    }
    .mb-lg-11,
    .my-lg-11 {
        margin-bottom: 6rem!important
    }
    .ml-lg-11,
    .mx-lg-11 {
        margin-left: 6rem!important
    }
    .m-lg-12 {
        margin: 8rem!important
    }
    .mt-lg-12,
    .my-lg-12 {
        margin-top: 8rem!important
    }
    .mr-lg-12,
    .mx-lg-12 {
        margin-right: 8rem!important
    }
    .mb-lg-12,
    .my-lg-12 {
        margin-bottom: 8rem!important
    }
    .ml-lg-12,
    .mx-lg-12 {
        margin-left: 8rem!important
    }
    .m-lg-13 {
        margin: 10rem!important
    }
    .mt-lg-13,
    .my-lg-13 {
        margin-top: 10rem!important
    }
    .mr-lg-13,
    .mx-lg-13 {
        margin-right: 10rem!important
    }
    .mb-lg-13,
    .my-lg-13 {
        margin-bottom: 10rem!important
    }
    .ml-lg-13,
    .mx-lg-13 {
        margin-left: 10rem!important
    }
    .m-lg-14 {
        margin: 12rem!important
    }
    .mt-lg-14,
    .my-lg-14 {
        margin-top: 12rem!important
    }
    .mr-lg-14,
    .mx-lg-14 {
        margin-right: 12rem!important
    }
    .mb-lg-14,
    .my-lg-14 {
        margin-bottom: 12rem!important
    }
    .ml-lg-14,
    .mx-lg-14 {
        margin-left: 12rem!important
    }
    .m-lg-15 {
        margin: 16rem!important
    }
    .mt-lg-15,
    .my-lg-15 {
        margin-top: 16rem!important
    }
    .mr-lg-15,
    .mx-lg-15 {
        margin-right: 16rem!important
    }
    .mb-lg-15,
    .my-lg-15 {
        margin-bottom: 16rem!important
    }
    .ml-lg-15,
    .mx-lg-15 {
        margin-left: 16rem!important
    }
    .m-lg-16 {
        margin: 25rem!important
    }
    .mt-lg-16,
    .my-lg-16 {
        margin-top: 25rem!important
    }
    .mr-lg-16,
    .mx-lg-16 {
        margin-right: 25rem!important
    }
    .mb-lg-16,
    .my-lg-16 {
        margin-bottom: 25rem!important
    }
    .ml-lg-16,
    .mx-lg-16 {
        margin-left: 25rem!important
    }
    .p-lg-0 {
        padding: 0!important
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0!important
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0!important
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0!important
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0!important
    }
    .p-lg-1 {
        padding: .25rem!important
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem!important
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem!important
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem!important
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem!important
    }
    .p-lg-2 {
        padding: .5rem!important
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem!important
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem!important
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem!important
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem!important
    }
    .p-lg-3 {
        padding: .75rem!important
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: .75rem!important
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: .75rem!important
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: .75rem!important
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: .75rem!important
    }
    .p-lg-4 {
        padding: 1rem!important
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1rem!important
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1rem!important
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1rem!important
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1rem!important
    }
    .p-lg-5 {
        padding: 1.5rem!important
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 1.5rem!important
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 1.5rem!important
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 1.5rem!important
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 1.5rem!important
    }
    .p-lg-6 {
        padding: 2rem!important
    }
    .pt-lg-6,
    .py-lg-6 {
        padding-top: 2rem!important
    }
    .pr-lg-6,
    .px-lg-6 {
        padding-right: 2rem!important
    }
    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 2rem!important
    }
    .pl-lg-6,
    .px-lg-6 {
        padding-left: 2rem!important
    }
    .p-lg-7 {
        padding: 2.5rem!important
    }
    .pt-lg-7,
    .py-lg-7 {
        padding-top: 2.5rem!important
    }
    .pr-lg-7,
    .px-lg-7 {
        padding-right: 2.5rem!important
    }
    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 2.5rem!important
    }
    .pl-lg-7,
    .px-lg-7 {
        padding-left: 2.5rem!important
    }
    .p-lg-8 {
        padding: 3rem!important
    }
    .pt-lg-8,
    .py-lg-8 {
        padding-top: 3rem!important
    }
    .pr-lg-8,
    .px-lg-8 {
        padding-right: 3rem!important
    }
    .pb-lg-8,
    .py-lg-8 {
        padding-bottom: 3rem!important
    }
    .pl-lg-8,
    .px-lg-8 {
        padding-left: 3rem!important
    }
    .p-lg-9 {
        padding: 4rem!important
    }
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 4rem!important
    }
    .pr-lg-9,
    .px-lg-9 {
        padding-right: 4rem!important
    }
    .pb-lg-9,
    .py-lg-9 {
        padding-bottom: 4rem!important
    }
    .pl-lg-9,
    .px-lg-9 {
        padding-left: 4rem!important
    }
    .p-lg-10 {
        padding: 5rem!important
    }
    .pt-lg-10,
    .py-lg-10 {
        padding-top: 5rem!important
    }
    .pr-lg-10,
    .px-lg-10 {
        padding-right: 5rem!important
    }
    .pb-lg-10,
    .py-lg-10 {
        padding-bottom: 5rem!important
    }
    .pl-lg-10,
    .px-lg-10 {
        padding-left: 5rem!important
    }
    .p-lg-11 {
        padding: 6rem!important
    }
    .pt-lg-11,
    .py-lg-11 {
        padding-top: 6rem!important
    }
    .pr-lg-11,
    .px-lg-11 {
        padding-right: 6rem!important
    }
    .pb-lg-11,
    .py-lg-11 {
        padding-bottom: 6rem!important
    }
    .pl-lg-11,
    .px-lg-11 {
        padding-left: 6rem!important
    }
    .p-lg-12 {
        padding: 8rem!important
    }
    .pt-lg-12,
    .py-lg-12 {
        padding-top: 8rem!important
    }
    .pr-lg-12,
    .px-lg-12 {
        padding-right: 8rem!important
    }
    .pb-lg-12,
    .py-lg-12 {
        padding-bottom: 8rem!important
    }
    .pl-lg-12,
    .px-lg-12 {
        padding-left: 8rem!important
    }
    .p-lg-13 {
        padding: 10rem!important
    }
    .pt-lg-13,
    .py-lg-13 {
        padding-top: 10rem!important
    }
    .pr-lg-13,
    .px-lg-13 {
        padding-right: 10rem!important
    }
    .pb-lg-13,
    .py-lg-13 {
        padding-bottom: 10rem!important
    }
    .pl-lg-13,
    .px-lg-13 {
        padding-left: 10rem!important
    }
    .p-lg-14 {
        padding: 12rem!important
    }
    .pt-lg-14,
    .py-lg-14 {
        padding-top: 12rem!important
    }
    .pr-lg-14,
    .px-lg-14 {
        padding-right: 12rem!important
    }
    .pb-lg-14,
    .py-lg-14 {
        padding-bottom: 12rem!important
    }
    .pl-lg-14,
    .px-lg-14 {
        padding-left: 12rem!important
    }
    .p-lg-15 {
        padding: 16rem!important
    }
    .pt-lg-15,
    .py-lg-15 {
        padding-top: 16rem!important
    }
    .pr-lg-15,
    .px-lg-15 {
        padding-right: 16rem!important
    }
    .pb-lg-15,
    .py-lg-15 {
        padding-bottom: 16rem!important
    }
    .pl-lg-15,
    .px-lg-15 {
        padding-left: 16rem!important
    }
    .p-lg-16 {
        padding: 25rem!important
    }
    .pt-lg-16,
    .py-lg-16 {
        padding-top: 25rem!important
    }
    .pr-lg-16,
    .px-lg-16 {
        padding-right: 25rem!important
    }
    .pb-lg-16,
    .py-lg-16 {
        padding-bottom: 25rem!important
    }
    .pl-lg-16,
    .px-lg-16 {
        padding-left: 25rem!important
    }
    .m-lg-n1 {
        margin: -.25rem!important
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem!important
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem!important
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem!important
    }
    .m-lg-n2 {
        margin: -.5rem!important
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem!important
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem!important
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem!important
    }
    .m-lg-n3 {
        margin: -.75rem!important
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -.75rem!important
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -.75rem!important
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -.75rem!important
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -.75rem!important
    }
    .m-lg-n4 {
        margin: -1rem!important
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1rem!important
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1rem!important
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1rem!important
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1rem!important
    }
    .m-lg-n5 {
        margin: -1.5rem!important
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -1.5rem!important
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -1.5rem!important
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -1.5rem!important
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -1.5rem!important
    }
    .m-lg-n6 {
        margin: -2rem!important
    }
    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -2rem!important
    }
    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -2rem!important
    }
    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -2rem!important
    }
    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -2rem!important
    }
    .m-lg-n7 {
        margin: -2.5rem!important
    }
    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -2.5rem!important
    }
    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -2.5rem!important
    }
    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -2.5rem!important
    }
    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -2.5rem!important
    }
    .m-lg-n8 {
        margin: -3rem!important
    }
    .mt-lg-n8,
    .my-lg-n8 {
        margin-top: -3rem!important
    }
    .mr-lg-n8,
    .mx-lg-n8 {
        margin-right: -3rem!important
    }
    .mb-lg-n8,
    .my-lg-n8 {
        margin-bottom: -3rem!important
    }
    .ml-lg-n8,
    .mx-lg-n8 {
        margin-left: -3rem!important
    }
    .m-lg-n9 {
        margin: -4rem!important
    }
    .mt-lg-n9,
    .my-lg-n9 {
        margin-top: -4rem!important
    }
    .mr-lg-n9,
    .mx-lg-n9 {
        margin-right: -4rem!important
    }
    .mb-lg-n9,
    .my-lg-n9 {
        margin-bottom: -4rem!important
    }
    .ml-lg-n9,
    .mx-lg-n9 {
        margin-left: -4rem!important
    }
    .m-lg-n10 {
        margin: -5rem!important
    }
    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -5rem!important
    }
    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -5rem!important
    }
    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -5rem!important
    }
    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -5rem!important
    }
    .m-lg-n11 {
        margin: -6rem!important
    }
    .mt-lg-n11,
    .my-lg-n11 {
        margin-top: -6rem!important
    }
    .mr-lg-n11,
    .mx-lg-n11 {
        margin-right: -6rem!important
    }
    .mb-lg-n11,
    .my-lg-n11 {
        margin-bottom: -6rem!important
    }
    .ml-lg-n11,
    .mx-lg-n11 {
        margin-left: -6rem!important
    }
    .m-lg-n12 {
        margin: -8rem!important
    }
    .mt-lg-n12,
    .my-lg-n12 {
        margin-top: -8rem!important
    }
    .mr-lg-n12,
    .mx-lg-n12 {
        margin-right: -8rem!important
    }
    .mb-lg-n12,
    .my-lg-n12 {
        margin-bottom: -8rem!important
    }
    .ml-lg-n12,
    .mx-lg-n12 {
        margin-left: -8rem!important
    }
    .m-lg-n13 {
        margin: -10rem!important
    }
    .mt-lg-n13,
    .my-lg-n13 {
        margin-top: -10rem!important
    }
    .mr-lg-n13,
    .mx-lg-n13 {
        margin-right: -10rem!important
    }
    .mb-lg-n13,
    .my-lg-n13 {
        margin-bottom: -10rem!important
    }
    .ml-lg-n13,
    .mx-lg-n13 {
        margin-left: -10rem!important
    }
    .m-lg-n14 {
        margin: -12rem!important
    }
    .mt-lg-n14,
    .my-lg-n14 {
        margin-top: -12rem!important
    }
    .mr-lg-n14,
    .mx-lg-n14 {
        margin-right: -12rem!important
    }
    .mb-lg-n14,
    .my-lg-n14 {
        margin-bottom: -12rem!important
    }
    .ml-lg-n14,
    .mx-lg-n14 {
        margin-left: -12rem!important
    }
    .m-lg-n15 {
        margin: -16rem!important
    }
    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -16rem!important
    }
    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -16rem!important
    }
    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -16rem!important
    }
    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -16rem!important
    }
    .m-lg-n16 {
        margin: -25rem!important
    }
    .mt-lg-n16,
    .my-lg-n16 {
        margin-top: -25rem!important
    }
    .mr-lg-n16,
    .mx-lg-n16 {
        margin-right: -25rem!important
    }
    .mb-lg-n16,
    .my-lg-n16 {
        margin-bottom: -25rem!important
    }
    .ml-lg-n16,
    .mx-lg-n16 {
        margin-left: -25rem!important
    }
    .m-lg-auto {
        margin: auto!important
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto!important
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto!important
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto!important
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto!important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0!important
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0!important
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0!important
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0!important
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0!important
    }
    .m-xl-1 {
        margin: .25rem!important
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem!important
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem!important
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem!important
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem!important
    }
    .m-xl-2 {
        margin: .5rem!important
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem!important
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem!important
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem!important
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem!important
    }
    .m-xl-3 {
        margin: .75rem!important
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: .75rem!important
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: .75rem!important
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: .75rem!important
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: .75rem!important
    }
    .m-xl-4 {
        margin: 1rem!important
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1rem!important
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1rem!important
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1rem!important
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1rem!important
    }
    .m-xl-5 {
        margin: 1.5rem!important
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 1.5rem!important
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 1.5rem!important
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 1.5rem!important
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 1.5rem!important
    }
    .m-xl-6 {
        margin: 2rem!important
    }
    .mt-xl-6,
    .my-xl-6 {
        margin-top: 2rem!important
    }
    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 2rem!important
    }
    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 2rem!important
    }
    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 2rem!important
    }
    .m-xl-7 {
        margin: 2.5rem!important
    }
    .mt-xl-7,
    .my-xl-7 {
        margin-top: 2.5rem!important
    }
    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 2.5rem!important
    }
    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 2.5rem!important
    }
    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 2.5rem!important
    }
    .m-xl-8 {
        margin: 3rem!important
    }
    .mt-xl-8,
    .my-xl-8 {
        margin-top: 3rem!important
    }
    .mr-xl-8,
    .mx-xl-8 {
        margin-right: 3rem!important
    }
    .mb-xl-8,
    .my-xl-8 {
        margin-bottom: 3rem!important
    }
    .ml-xl-8,
    .mx-xl-8 {
        margin-left: 3rem!important
    }
    .m-xl-9 {
        margin: 4rem!important
    }
    .mt-xl-9,
    .my-xl-9 {
        margin-top: 4rem!important
    }
    .mr-xl-9,
    .mx-xl-9 {
        margin-right: 4rem!important
    }
    .mb-xl-9,
    .my-xl-9 {
        margin-bottom: 4rem!important
    }
    .ml-xl-9,
    .mx-xl-9 {
        margin-left: 4rem!important
    }
    .m-xl-10 {
        margin: 5rem!important
    }
    .mt-xl-10,
    .my-xl-10 {
        margin-top: 5rem!important
    }
    .mr-xl-10,
    .mx-xl-10 {
        margin-right: 5rem!important
    }
    .mb-xl-10,
    .my-xl-10 {
        margin-bottom: 5rem!important
    }
    .ml-xl-10,
    .mx-xl-10 {
        margin-left: 5rem!important
    }
    .m-xl-11 {
        margin: 6rem!important
    }
    .mt-xl-11,
    .my-xl-11 {
        margin-top: 6rem!important
    }
    .mr-xl-11,
    .mx-xl-11 {
        margin-right: 6rem!important
    }
    .mb-xl-11,
    .my-xl-11 {
        margin-bottom: 6rem!important
    }
    .ml-xl-11,
    .mx-xl-11 {
        margin-left: 6rem!important
    }
    .m-xl-12 {
        margin: 8rem!important
    }
    .mt-xl-12,
    .my-xl-12 {
        margin-top: 8rem!important
    }
    .mr-xl-12,
    .mx-xl-12 {
        margin-right: 8rem!important
    }
    .mb-xl-12,
    .my-xl-12 {
        margin-bottom: 8rem!important
    }
    .ml-xl-12,
    .mx-xl-12 {
        margin-left: 8rem!important
    }
    .m-xl-13 {
        margin: 10rem!important
    }
    .mt-xl-13,
    .my-xl-13 {
        margin-top: 10rem!important
    }
    .mr-xl-13,
    .mx-xl-13 {
        margin-right: 10rem!important
    }
    .mb-xl-13,
    .my-xl-13 {
        margin-bottom: 10rem!important
    }
    .ml-xl-13,
    .mx-xl-13 {
        margin-left: 10rem!important
    }
    .m-xl-14 {
        margin: 12rem!important
    }
    .mt-xl-14,
    .my-xl-14 {
        margin-top: 12rem!important
    }
    .mr-xl-14,
    .mx-xl-14 {
        margin-right: 12rem!important
    }
    .mb-xl-14,
    .my-xl-14 {
        margin-bottom: 12rem!important
    }
    .ml-xl-14,
    .mx-xl-14 {
        margin-left: 12rem!important
    }
    .m-xl-15 {
        margin: 16rem!important
    }
    .mt-xl-15,
    .my-xl-15 {
        margin-top: 16rem!important
    }
    .mr-xl-15,
    .mx-xl-15 {
        margin-right: 16rem!important
    }
    .mb-xl-15,
    .my-xl-15 {
        margin-bottom: 16rem!important
    }
    .ml-xl-15,
    .mx-xl-15 {
        margin-left: 16rem!important
    }
    .m-xl-16 {
        margin: 25rem!important
    }
    .mt-xl-16,
    .my-xl-16 {
        margin-top: 25rem!important
    }
    .mr-xl-16,
    .mx-xl-16 {
        margin-right: 25rem!important
    }
    .mb-xl-16,
    .my-xl-16 {
        margin-bottom: 25rem!important
    }
    .ml-xl-16,
    .mx-xl-16 {
        margin-left: 25rem!important
    }
    .p-xl-0 {
        padding: 0!important
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0!important
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0!important
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0!important
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0!important
    }
    .p-xl-1 {
        padding: .25rem!important
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem!important
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem!important
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem!important
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem!important
    }
    .p-xl-2 {
        padding: .5rem!important
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem!important
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem!important
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem!important
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem!important
    }
    .p-xl-3 {
        padding: .75rem!important
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: .75rem!important
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: .75rem!important
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: .75rem!important
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: .75rem!important
    }
    .p-xl-4 {
        padding: 1rem!important
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1rem!important
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1rem!important
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1rem!important
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1rem!important
    }
    .p-xl-5 {
        padding: 1.5rem!important
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 1.5rem!important
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 1.5rem!important
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 1.5rem!important
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 1.5rem!important
    }
    .p-xl-6 {
        padding: 2rem!important
    }
    .pt-xl-6,
    .py-xl-6 {
        padding-top: 2rem!important
    }
    .pr-xl-6,
    .px-xl-6 {
        padding-right: 2rem!important
    }
    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 2rem!important
    }
    .pl-xl-6,
    .px-xl-6 {
        padding-left: 2rem!important
    }
    .p-xl-7 {
        padding: 2.5rem!important
    }
    .pt-xl-7,
    .py-xl-7 {
        padding-top: 2.5rem!important
    }
    .pr-xl-7,
    .px-xl-7 {
        padding-right: 2.5rem!important
    }
    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 2.5rem!important
    }
    .pl-xl-7,
    .px-xl-7 {
        padding-left: 2.5rem!important
    }
    .p-xl-8 {
        padding: 3rem!important
    }
    .pt-xl-8,
    .py-xl-8 {
        padding-top: 3rem!important
    }
    .pr-xl-8,
    .px-xl-8 {
        padding-right: 3rem!important
    }
    .pb-xl-8,
    .py-xl-8 {
        padding-bottom: 3rem!important
    }
    .pl-xl-8,
    .px-xl-8 {
        padding-left: 3rem!important
    }
    .p-xl-9 {
        padding: 4rem!important
    }
    .pt-xl-9,
    .py-xl-9 {
        padding-top: 4rem!important
    }
    .pr-xl-9,
    .px-xl-9 {
        padding-right: 4rem!important
    }
    .pb-xl-9,
    .py-xl-9 {
        padding-bottom: 4rem!important
    }
    .pl-xl-9,
    .px-xl-9 {
        padding-left: 4rem!important
    }
    .p-xl-10 {
        padding: 5rem!important
    }
    .pt-xl-10,
    .py-xl-10 {
        padding-top: 5rem!important
    }
    .pr-xl-10,
    .px-xl-10 {
        padding-right: 5rem!important
    }
    .pb-xl-10,
    .py-xl-10 {
        padding-bottom: 5rem!important
    }
    .pl-xl-10,
    .px-xl-10 {
        padding-left: 5rem!important
    }
    .p-xl-11 {
        padding: 6rem!important
    }
    .pt-xl-11,
    .py-xl-11 {
        padding-top: 6rem!important
    }
    .pr-xl-11,
    .px-xl-11 {
        padding-right: 6rem!important
    }
    .pb-xl-11,
    .py-xl-11 {
        padding-bottom: 6rem!important
    }
    .pl-xl-11,
    .px-xl-11 {
        padding-left: 6rem!important
    }
    .p-xl-12 {
        padding: 8rem!important
    }
    .pt-xl-12,
    .py-xl-12 {
        padding-top: 8rem!important
    }
    .pr-xl-12,
    .px-xl-12 {
        padding-right: 8rem!important
    }
    .pb-xl-12,
    .py-xl-12 {
        padding-bottom: 8rem!important
    }
    .pl-xl-12,
    .px-xl-12 {
        padding-left: 8rem!important
    }
    .p-xl-13 {
        padding: 10rem!important
    }
    .pt-xl-13,
    .py-xl-13 {
        padding-top: 10rem!important
    }
    .pr-xl-13,
    .px-xl-13 {
        padding-right: 10rem!important
    }
    .pb-xl-13,
    .py-xl-13 {
        padding-bottom: 10rem!important
    }
    .pl-xl-13,
    .px-xl-13 {
        padding-left: 10rem!important
    }
    .p-xl-14 {
        padding: 12rem!important
    }
    .pt-xl-14,
    .py-xl-14 {
        padding-top: 12rem!important
    }
    .pr-xl-14,
    .px-xl-14 {
        padding-right: 12rem!important
    }
    .pb-xl-14,
    .py-xl-14 {
        padding-bottom: 12rem!important
    }
    .pl-xl-14,
    .px-xl-14 {
        padding-left: 12rem!important
    }
    .p-xl-15 {
        padding: 16rem!important
    }
    .pt-xl-15,
    .py-xl-15 {
        padding-top: 16rem!important
    }
    .pr-xl-15,
    .px-xl-15 {
        padding-right: 16rem!important
    }
    .pb-xl-15,
    .py-xl-15 {
        padding-bottom: 16rem!important
    }
    .pl-xl-15,
    .px-xl-15 {
        padding-left: 16rem!important
    }
    .p-xl-16 {
        padding: 25rem!important
    }
    .pt-xl-16,
    .py-xl-16 {
        padding-top: 25rem!important
    }
    .pr-xl-16,
    .px-xl-16 {
        padding-right: 25rem!important
    }
    .pb-xl-16,
    .py-xl-16 {
        padding-bottom: 25rem!important
    }
    .pl-xl-16,
    .px-xl-16 {
        padding-left: 25rem!important
    }
    .m-xl-n1 {
        margin: -.25rem!important
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem!important
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem!important
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem!important
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem!important
    }
    .m-xl-n2 {
        margin: -.5rem!important
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem!important
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem!important
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem!important
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem!important
    }
    .m-xl-n3 {
        margin: -.75rem!important
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -.75rem!important
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -.75rem!important
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -.75rem!important
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -.75rem!important
    }
    .m-xl-n4 {
        margin: -1rem!important
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1rem!important
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1rem!important
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1rem!important
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1rem!important
    }
    .m-xl-n5 {
        margin: -1.5rem!important
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -1.5rem!important
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -1.5rem!important
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -1.5rem!important
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -1.5rem!important
    }
    .m-xl-n6 {
        margin: -2rem!important
    }
    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -2rem!important
    }
    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -2rem!important
    }
    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -2rem!important
    }
    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -2rem!important
    }
    .m-xl-n7 {
        margin: -2.5rem!important
    }
    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -2.5rem!important
    }
    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -2.5rem!important
    }
    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -2.5rem!important
    }
    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -2.5rem!important
    }
    .m-xl-n8 {
        margin: -3rem!important
    }
    .mt-xl-n8,
    .my-xl-n8 {
        margin-top: -3rem!important
    }
    .mr-xl-n8,
    .mx-xl-n8 {
        margin-right: -3rem!important
    }
    .mb-xl-n8,
    .my-xl-n8 {
        margin-bottom: -3rem!important
    }
    .ml-xl-n8,
    .mx-xl-n8 {
        margin-left: -3rem!important
    }
    .m-xl-n9 {
        margin: -4rem!important
    }
    .mt-xl-n9,
    .my-xl-n9 {
        margin-top: -4rem!important
    }
    .mr-xl-n9,
    .mx-xl-n9 {
        margin-right: -4rem!important
    }
    .mb-xl-n9,
    .my-xl-n9 {
        margin-bottom: -4rem!important
    }
    .ml-xl-n9,
    .mx-xl-n9 {
        margin-left: -4rem!important
    }
    .m-xl-n10 {
        margin: -5rem!important
    }
    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -5rem!important
    }
    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -5rem!important
    }
    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -5rem!important
    }
    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -5rem!important
    }
    .m-xl-n11 {
        margin: -6rem!important
    }
    .mt-xl-n11,
    .my-xl-n11 {
        margin-top: -6rem!important
    }
    .mr-xl-n11,
    .mx-xl-n11 {
        margin-right: -6rem!important
    }
    .mb-xl-n11,
    .my-xl-n11 {
        margin-bottom: -6rem!important
    }
    .ml-xl-n11,
    .mx-xl-n11 {
        margin-left: -6rem!important
    }
    .m-xl-n12 {
        margin: -8rem!important
    }
    .mt-xl-n12,
    .my-xl-n12 {
        margin-top: -8rem!important
    }
    .mr-xl-n12,
    .mx-xl-n12 {
        margin-right: -8rem!important
    }
    .mb-xl-n12,
    .my-xl-n12 {
        margin-bottom: -8rem!important
    }
    .ml-xl-n12,
    .mx-xl-n12 {
        margin-left: -8rem!important
    }
    .m-xl-n13 {
        margin: -10rem!important
    }
    .mt-xl-n13,
    .my-xl-n13 {
        margin-top: -10rem!important
    }
    .mr-xl-n13,
    .mx-xl-n13 {
        margin-right: -10rem!important
    }
    .mb-xl-n13,
    .my-xl-n13 {
        margin-bottom: -10rem!important
    }
    .ml-xl-n13,
    .mx-xl-n13 {
        margin-left: -10rem!important
    }
    .m-xl-n14 {
        margin: -12rem!important
    }
    .mt-xl-n14,
    .my-xl-n14 {
        margin-top: -12rem!important
    }
    .mr-xl-n14,
    .mx-xl-n14 {
        margin-right: -12rem!important
    }
    .mb-xl-n14,
    .my-xl-n14 {
        margin-bottom: -12rem!important
    }
    .ml-xl-n14,
    .mx-xl-n14 {
        margin-left: -12rem!important
    }
    .m-xl-n15 {
        margin: -16rem!important
    }
    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -16rem!important
    }
    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -16rem!important
    }
    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -16rem!important
    }
    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -16rem!important
    }
    .m-xl-n16 {
        margin: -25rem!important
    }
    .mt-xl-n16,
    .my-xl-n16 {
        margin-top: -25rem!important
    }
    .mr-xl-n16,
    .mx-xl-n16 {
        margin-right: -25rem!important
    }
    .mb-xl-n16,
    .my-xl-n16 {
        margin-bottom: -25rem!important
    }
    .ml-xl-n16,
    .mx-xl-n16 {
        margin-left: -25rem!important
    }
    .m-xl-auto {
        margin: auto!important
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto!important
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto!important
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto!important
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto!important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace!important
}

.text-justify {
    text-align: justify!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left!important
}

.text-right {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left!important
    }
    .text-sm-right {
        text-align: right!important
    }
    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left!important
    }
    .text-md-right {
        text-align: right!important
    }
    .text-md-center {
        text-align: center!important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left!important
    }
    .text-lg-right {
        text-align: right!important
    }
    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left!important
    }
    .text-xl-right {
        text-align: right!important
    }
    .text-xl-center {
        text-align: center!important
    }
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.font-weight-light {
    font-weight: 300!important
}

.font-weight-lighter {
    font-weight: lighter!important
}

.font-weight-normal {
    font-weight: 400!important
}

.font-weight-bold {
    font-weight: 600!important
}

.font-weight-bolder {
    font-weight: bolder!important
}

.font-italic {
    font-style: italic!important
}

.text-white {
    color: #fff!important
}

.text-primary {
    color: #165985!important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #133bbd!important
}

.text-secondary {
    color: #506690!important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #35435f!important
}

.text-success {
    color: #42ba96!important
}

a.text-success:focus,
a.text-success:hover {
    color: #2e8268!important
}

.text-info {
    color: #7c69ef!important
}

a.text-info:focus,
a.text-info:hover {
    color: #4024e8!important
}

.text-warning {
    color: #fad776!important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #f7c12c!important
}

.text-danger {
    color: #df4759!important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #b92032!important
}

.text-light {
    color: #f9fbfd!important
}

a.text-light:focus,
a.text-light:hover {
    color: #c0d5ea!important
}

.text-dark {
    color: #2b354f!important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #10141d!important
}

.text-primary-desat {
    color: #6c8aec!important
}

a.text-primary-desat:focus,
a.text-primary-desat:hover {
    color: #2854e3!important
}

.text-body {
    color: #161c2d!important
}

.text-muted {
    color: #869ab8!important
}

.text-black-50 {
    color: rgba(22, 28, 45, .5)!important
}

.text-white-50 {
    color: hsla(0, 0%, 100%, .5)!important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none!important
}

.text-break {
    word-break: break-word!important;
    overflow-wrap: break-word!important
}

.text-reset {
    color: inherit!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media print {
    *,
     :after,
     :before {
        text-shadow: none!important;
        box-shadow: none!important
    }
    a:not(.btn) {
        text-decoration: underline
    }
    abbr[title]:after {
        content: " (" attr(title) ")"
    }
    pre {
        white-space: pre-wrap!important
    }
    blockquote,
    pre {
        border: 1px solid #abbcd5;
        page-break-inside: avoid
    }
    thead {
        display: table-header-group
    }
    img,
    tr {
        page-break-inside: avoid
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
    @page {
        size: a3
    }
    .container,
    body {
        min-width: 992px!important
    }
    .navbar {
        display: none
    }
    .badge {
        border: 1px solid #161c2d
    }
    .table {
        border-collapse: collapse!important
    }
    .table td,
    .table th {
        background-color: #fff!important
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #d9e2ef!important
    }
    .table-dark {
        color: inherit
    }
    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #f1f4f8
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #f1f4f8
    }
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.35rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #293c74;
    background-color: #dce3f9;
    border-color: #cdd8f6;
}

.alert-primary hr {
    border-top-color: #b7c7f2;
}

.alert-primary .alert-link {
    color: #1c294e;
}

.alert-secondary {
    color: #45464e;
    background-color: #e7e7ea;
    border-color: #dddde2;
}

.alert-secondary hr {
    border-top-color: #cfcfd6;
}

.alert-secondary .alert-link {
    color: #2d2e33;
}

.alert-success {
    color: #0f6848;
    background-color: #d2f4e8;
    border-color: #bff0de;
}

.alert-success hr {
    border-top-color: #aaebd3;
}

.alert-success .alert-link {
    color: #093b29;
}

.alert-info {
    color: #1c606a;
    background-color: #d7f1f5;
    border-color: #c7ebf1;
}

.alert-info hr {
    border-top-color: #b3e4ec;
}

.alert-info .alert-link {
    color: #113b42;
}

.alert-warning {
    color: #806520;
    background-color: #fdf3d8;
    border-color: #fceec9;
}

.alert-warning hr {
    border-top-color: #fbe6b1;
}

.alert-warning .alert-link {
    color: #574516;
}

.alert-danger {
    color: #78261f;
    background-color: #fadbd8;
    border-color: #f8ccc8;
}

.alert-danger hr {
    border-top-color: #f5b7b1;
}

.alert-danger .alert-link {
    color: #4f1915;
}

.alert-light {
    color: #818183;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686869;
}

.alert-dark {
    color: #2f3037;
    background-color: #dedee1;
    border-color: #d1d1d5;
}

.alert-dark hr {
    border-top-color: #c4c4c9;
}

.alert-dark .alert-link {
    color: #18181c;
}

.badge-primary-soft {
    background-color: rgba(51, 94, 234, .1);
    color: #165985
}

.badge-secondary-soft {
    background-color: rgba(80, 102, 144, .1);
    color: #506690
}

.badge-success-soft {
    background-color: rgba(66, 186, 150, .1);
    color: #42ba96
}

.badge-info-soft {
    background-color: rgba(124, 105, 239, .1);
    color: #7c69ef
}

.badge-warning-soft {
    background-color: hsla(44, 93%, 72%, .1);
    color: #fad776
}

.badge-danger-soft {
    background-color: rgba(223, 71, 89, .1);
    color: #df4759
}

.badge-light-soft {
    background-color: rgba(249, 251, 253, .1);
    color: #f9fbfd
}

.badge-dark-soft {
    background-color: rgba(43, 53, 79, .1);
    color: #2b354f
}

.badge-primary-desat-soft {
    background-color: rgba(108, 138, 236, .1);
    color: #6c8aec
}

.badge-gray-700-soft {
    background-color: rgba(80, 102, 144, .1);
    color: #506690
}

.badge-lg {
    padding: .7em 1.15em
}

.badge-rounded-circle {
    height: calc(1em + .5rem);
    padding-left: .25rem;
    padding-right: .25rem;
    border-radius: 10rem
}

.badge-rounded-circle>* {
    display: block;
    width: 1em
}

.badge-rounded-circle.badge-lg {
    height: 2.4em;
    padding-left: .7em;
    padding-right: .7em
}

.breadcrumb-item {
    font-size: .9375rem
}

.breadcrumb-item+.breadcrumb-item:before {
    content: "\e930";
    font-family: Feather
}

.breadcrumb-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto
}

.breadcrumb-scroll .breadcrumb-item {
    white-space: nowrap
}

.breadcrumb-scroll::-webkit-scrollbar {
    display: none
}

.btn>img {
    max-height: 1em;
    width: auto
}

.btn:not([class*=btn-outline]) {
    border-color: transparent!important
}

.btn-white {
    background-color: #fff;
    color: #165985
}

.btn-white:focus,
.btn-white:hover {
    background-color: #fff;
    color: #1c4ce8
}

.btn-group .btn-white {
    border-color: #d9e2ef!important;
    color: #869ab8
}

.btn-group .btn-white.active {
    background-color: #f2f2f2;
    color: #768daf;
    box-shadow: none
}

.btn-gray-400 {
    background-color: #c6d3e6;
    color: #fff
}

.btn-gray-400:focus,
.btn-gray-400:hover {
    background-color: #b4c5de;
    color: #f2f2f2
}

.btn-primary-soft {
    background-color: rgba(51, 94, 234, .1);
    color: #165985
}

.btn-primary-soft:focus,
.btn-primary-soft:hover {
    background-color: rgba(51, 94, 234, .15);
    color: #165985
}

.btn-secondary-soft {
    background-color: rgba(80, 102, 144, .1);
    color: #506690
}

.btn-secondary-soft:focus,
.btn-secondary-soft:hover {
    background-color: rgba(80, 102, 144, .15);
    color: #506690
}

.btn-success-soft {
    background-color: rgba(66, 186, 150, .1);
    color: #42ba96
}

.btn-success-soft:focus,
.btn-success-soft:hover {
    background-color: rgba(66, 186, 150, .15);
    color: #42ba96
}

.btn-info-soft {
    background-color: rgba(124, 105, 239, .1);
    color: #7c69ef
}

.btn-info-soft:focus,
.btn-info-soft:hover {
    background-color: rgba(124, 105, 239, .15);
    color: #7c69ef
}

.btn-warning-soft {
    background-color: hsla(44, 93%, 72%, .1);
    color: #fad776
}

.btn-warning-soft:focus,
.btn-warning-soft:hover {
    background-color: hsla(44, 93%, 72%, .15);
    color: #fad776
}

.btn-danger-soft {
    background-color: rgba(223, 71, 89, .1);
    color: #df4759
}

.btn-danger-soft:focus,
.btn-danger-soft:hover {
    background-color: rgba(223, 71, 89, .15);
    color: #df4759
}

.btn-light-soft {
    background-color: rgba(249, 251, 253, .1);
    color: #f9fbfd
}

.btn-light-soft:focus,
.btn-light-soft:hover {
    background-color: rgba(249, 251, 253, .15);
    color: #f9fbfd
}

.btn-dark-soft {
    background-color: rgba(43, 53, 79, .1);
    color: #2b354f
}

.btn-dark-soft:focus,
.btn-dark-soft:hover {
    background-color: rgba(43, 53, 79, .15);
    color: #2b354f
}

.btn-primary-desat-soft {
    background-color: rgba(108, 138, 236, .1);
    color: #6c8aec
}

.btn-primary-desat-soft:focus,
.btn-primary-desat-soft:hover {
    background-color: rgba(108, 138, 236, .15);
    color: #6c8aec
}

.btn-pill {
    border-radius: 50rem
}

.btn-rounded-circle {
    padding-left: .8125rem;
    padding-right: .8125rem;
    border-radius: 50rem
}

.btn-rounded-circle>* {
    display: block;
    width: 1em
}

.btn-group-lg>.btn-rounded-circle.btn,
.btn-rounded-circle.btn-lg {
    padding-left: 1.122rem;
    padding-right: 1.122rem
}

.btn-group-sm>.btn-rounded-circle.btn,
.btn-rounded-circle.btn-sm {
    padding-left: .5625rem;
    padding-right: .5625rem
}

.card {
    width: 100%
}

.card-body,
.card-img-top {
    min-height: 1px
}

.card-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.card-border {
    position: relative
}

.card-border:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top-width: 2px;
    border-bottom-width: calc(.375rem - 2px);
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: inherit;
    border-bottom-color: transparent;
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.card-border-lg:after {
    border-top-width: 3px;
    border-bottom-width: calc(.375rem - 3px)
}

.card-border-xl:after {
    border-top-width: 4px;
    border-bottom-width: calc(.375rem - 3px)
}

@media (min-width:576px) and (max-width:767.98px) {
    .card-group {
        flex-direction: column!important
    }
    .card-group>.card {
        flex: auto
    }
}

.close:focus,
.close:hover {
    outline: none
}

.custom-switch {
    height: 1.375rem;
    min-height: 1.375rem
}

.custom-switch .custom-control-label:before {
    top: .1625rem;
    height: 1.375rem;
    border-radius: .6875rem
}

.custom-switch .custom-control-label:after {
    top: .35rem;
    left: -2.8125rem;
    background-color: #fff
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    transform: translateX(1.125rem)
}

.custom-switch-dark .custom-control-label:before {
    background-color: hsla(0, 0%, 100%, .2)
}

.custom-switch-dark .custom-control-input:checked~.custom-control-label:before,
.custom-switch-dark .custom-control-label:after {
    background-color: #fff
}

.custom-switch-dark .custom-control-input:checked~.custom-control-label:after {
    background-color: #165985
}

.dropdown-menu {
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1)
}

.form-control-flush {
    padding-left: 0;
    padding-right: 0;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0
}

.custom-file-label:after {
    font-weight: 600
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(1.6em + 1.625rem + 2px);
    padding: .8125rem 1.25rem;
    margin-bottom: 0;
    line-height: 1.6;
    font-size: 1.0625rem;
    color: #869ab8;
    pointer-events: none;
    cursor: text;
    transition: all .2s ease-in-out
}

.form-label-group>.form-control-flush~label {
    padding-left: 0;
    padding-right: 0
}

.form-label-group>.form-control:-ms-input-placeholder,
.form-label-group>.form-control::-ms-input-placeholder {
    color: transparent
}

.form-label-group>.form-control::placeholder {
    color: transparent
}

.form-label-group>.form-control:not(:placeholder-shown) {
    padding-top: 1.21875rem;
    padding-bottom: .40625rem
}

.form-label-group>.form-control:not(:placeholder-shown)~label {
    padding-top: .00937rem;
    padding-bottom: .00937rem;
    font-size: .75rem
}

@supports (-ms-ime-align:auto) {
    .form-label-group>label {
        display: none
    }
    .form-label-group .form-control:-ms-input-placeholder,
    .form-label-group .form-control::-ms-input-placeholder {
        color: #869ab8
    }
    .form-label-group .form-control::placeholder {
        color: #869ab8
    }
}

@media (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .form-label-group>label {
        display: none
    }
    .form-label-group .form-control:-ms-input-placeholder,
    .form-label-group .form-control::-ms-input-placeholder {
        color: #869ab8
    }
    .form-label-group .form-control::placeholder {
        color: #869ab8
    }
}

.container,
.container-fluid {
    position: relative;
    z-index: 1
}

.img-cover {
    width: 100vw;
    height: auto;
    margin-left: calc(50% - 50vw)
}

.list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0
}

.list-group-flush .list-group-item:first-child {
    padding-top: 0
}

.list-group-flush .list-group-item:last-child {
    padding-bottom: 0
}

.navbar {
    transition: all .2s ease-in-out;
    transition-property: background-color, color, transform;
    z-index: 1030;
    perspective: 2000px
}

@media (max-width:991.98px) {
    .navbar-dark .navbar-nav .nav-item .nav-link {
        color: #506690
    }
    .navbar-dark .navbar-nav .nav-item.active .nav-link,
    .navbar-dark .navbar-nav .nav-item .nav-link.active,
    .navbar-dark .navbar-nav .nav-item .nav-link:focus,
    .navbar-dark .navbar-nav .nav-item .nav-link:hover {
        color: #165985
    }
    .navbar-dark .navbar-collapse .navbar-toggler {
        color: #506690
    }
}

.navbar>.container {
    padding-left: 0;
    padding-right: 0
}

@media (min-width:1200px) {
    .navbar>.container {
        padding-left: 20px;
        padding-right: 20px
    }
}

.navbar-brand {
    font-weight: 600
}

/*.navbar-brand-img {
    max-height: 2.4rem;
    width: auto
}

.navbar-dark .navbar-brand {
    filter: brightness(0) invert(1)
}*/

@media (max-width:991.98px) {
    .navbar-btn {
        width: 100%;
        padding: .8125rem 1.25rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 1.0625rem
    }
}

.navbar-nav .nav-link {
    font-weight: 600
}

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
    outline: none
}

.navbar-dark>.container {
    position: relative
}

.navbar-dark>.container:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1.25rem;
    left: 0;
    border-top: 1px solid hsla(0, 0%, 100%, .2)
}

@media (max-width:991.98px) {
    .navbar-collapse {
        position: fixed;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        height: auto!important;
        max-height: calc(100vh - 2rem);
        background-color: #fff;
        border-radius: .375rem;
        box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        transition: all .2s ease-in-out;
        transition-property: opacity, transform;
        transform-origin: top right
    }
    .navbar-collapse.show {
        opacity: 1;
        transform: scale(1)
    }
    .navbar-collapse.collapsing {
        opacity: 0;
        transform: scale(.9)
    }
}

.navbar-collapse .navbar-toggler {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1
}

@media (min-width:992px) {
    .navbar-collapse .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {
    .navbar-nav .nav-item {
        padding: 1.5rem
    }
    .navbar-nav .nav-item+.nav-item {
        border-top: 1px solid #f1f4f8
    }
}

@media (max-width:991.98px) {
    .navbar-nav .nav-link {
        padding-top: 0;
        padding-bottom: 0
    }
}

.navbar-nav .dropdown-menu {
    display: block!important;
    min-width: 0;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none
}

@media (min-width:992px) {
    .navbar-nav .dropdown-menu {
        left: 50%;
        min-width: 350px;
        padding: 2.5rem;
        box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15);
        visibility: hidden;
        opacity: 0;
        transform: translate3d(-50%, 10px, 0);
        transition: all .2s ease-in-out;
        transition-property: opacity, visibility, transform;
        transform-origin: top center
    }
    .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 670px
    }
}

.navbar-nav .dropdown-toggle:after {
    display: none
}

@media (max-width:991.98px) {
    .navbar-nav .dropdown-toggle {
        margin-bottom: 1.5rem;
        pointer-events: none
    }
}

@media (min-width:992px) {
    .navbar-nav .dropdown .dropdown-menu {
        z-index: 1030
    }
    .navbar-nav .dropdown:hover .dropdown-menu {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
        visibility: visible
    }
}

.pagination-sm .page-link {
    line-height: 1.3;
    font-size: 1.0625rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.pagination-lg .page-link {
    line-height: 1.3;
    font-size: 1.0625rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

body,
html {
    overflow-x: hidden
}

.table thead>tr>th {
    border-top-width: 0;
    border-bottom-width: 1px
}

.table-align-middle tbody>tr>td,
.table-align-middle thead>tr>th {
    vertical-align: middle
}

@font-face {
    font-family: HKGroteskPro;
    font-weight: 400;
    src: url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff2) format("woff2"), url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff) format("woff")
}

@font-face {
    font-family: HKGroteskPro;
    font-weight: 600;
    src: url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff2) format("woff2"), url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff) format("woff")
}

.h1,
.h2,
h1,
h2 {
    margin-bottom: 1rem
}

.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4 {
    letter-spacing: -.01em
}

.h1,
h1 {
    line-height: 1.3
}

@media (max-width:991.98px) {
    .h1,
    h1 {
        font-size: 1.875rem
    }
}

.h2,
h2 {
    line-height: 1.35
}

@media (max-width:991.98px) {
    .h2,
    h2 {
        font-size: 1.5rem
    }
}

.h3,
h3 {
    line-height: 1.4
}

@media (max-width:991.98px) {
    .h3,
    h3 {
        font-size: 1.125
    }
}

.h4,
h4 {
    line-height: 1.45
}

@media (max-width:991.98px) {
    .h4,
    h4 {
        font-size: 1.0625
    }
}

.h5,
h5 {
    line-height: 1.5
}

.h6,
h6 {
    line-height: 1.55
}

.h6.text-uppercase,
h6.text-uppercase {
    letter-spacing: .08em
}

.display-1,
.display-2,
.display-3,
.display-4 {
    letter-spacing: -.02em
}

.display-1 {
    line-height: 1.2
}

@media (max-width:991.98px) {
    .display-1 {
        font-size: 3.125rem
    }
}

.display-2 {
    line-height: 1.2
}

@media (max-width:991.98px) {
    .display-2 {
        font-size: 2.5rem
    }
}

.display-3 {
    line-height: 1.2
}

@media (max-width:991.98px) {
    .display-3 {
        font-size: 2.25rem
    }
}

.display-4 {
    line-height: 1.2
}

@media (max-width:991.98px) {
    .display-4 {
        font-size: 2rem
    }
}

@media (max-width:991.98px) {
    .lead {
        font-size: 1.1875rem
    }
}

.blockquote-img {
    max-height: 2.5rem;
    max-width: 100%;
    width: auto
}

.blockquote-footer:before {
    display: none
}

.list-social-icon {
    max-width: 1.25rem;
    height: auto
}

.hr-sm {
    width: 60px;
    border-top-width: 2px
}

code {
    line-height: 1.35
}

.list {
    padding-left: 0;
    list-style: none
}

.list-item+.list-item {
    margin-top: .25rem
}

.list-link {
    font-size: .9375rem;
    color: #506690
}

.list-link:focus,
.list-link:hover {
    text-decoration: none;
    color: #165985
}

.bg-cover {
    background: no-repeat 50%/cover
}

@media (max-width:991.98px) {
    .bg-between {
        background-image: none!important
    }
}

@media (min-width:768px) {
    .bg-between {
        background-repeat: no-repeat;
        background-position: 0, 100%;
        background-size: auto 70%
    }
}

.bg-gradient-light {
    background-image: linear-gradient(180deg, #f9fbfd 0, #fff)
}

.bg-gray-100 {
    background-color: #fafafa!important
}

.bg-gray-200 {
    background-color: #f1f4f8!important
}

.bg-gray-300 {
    background-color: #d9e2ef!important
}

.bg-gray-800 {
    background-color: #384c74!important
}

.bg-black {
    background-color: #161c2d!important
}

@media (min-width:576px) {
    .border-sm {
        border: 1px solid #f1f4f8!important;
        color: rose
    }
    .border-top-sm {
        border-top: 1px solid #f1f4f8!important
    }
    .border-right-sm {
        border-right: 1px solid #f1f4f8!important
    }
    .border-bottom-sm {
        border-bottom: 1px solid #f1f4f8!important
    }
    .border-left-sm {
        border-left: 1px solid #f1f4f8!important
    }
    .border-sm-0 {
        border: 0!important
    }
    .border-top-sm-0 {
        border-top: 0!important
    }
    .border-right-sm-0 {
        border-right: 0!important
    }
    .border-bottom-sm-0 {
        border-bottom: 0!important
    }
    .border-left-sm-0 {
        border-left: 0!important
    }
}

@media (min-width:768px) {
    .border-md {
        border: 1px solid #f1f4f8!important;
        color: rose
    }
    .border-top-md {
        border-top: 1px solid #f1f4f8!important
    }
    .border-right-md {
        border-right: 1px solid #f1f4f8!important
    }
    .border-bottom-md {
        border-bottom: 1px solid #f1f4f8!important
    }
    .border-left-md {
        border-left: 1px solid #f1f4f8!important
    }
    .border-md-0 {
        border: 0!important
    }
    .border-top-md-0 {
        border-top: 0!important
    }
    .border-right-md-0 {
        border-right: 0!important
    }
    .border-bottom-md-0 {
        border-bottom: 0!important
    }
    .border-left-md-0 {
        border-left: 0!important
    }
}

@media (min-width:992px) {
    .border-lg {
        border: 1px solid #f1f4f8!important;
        color: rose
    }
    .border-top-lg {
        border-top: 1px solid #f1f4f8!important
    }
    .border-right-lg {
        border-right: 1px solid #f1f4f8!important
    }
    .border-bottom-lg {
        border-bottom: 1px solid #f1f4f8!important
    }
    .border-left-lg {
        border-left: 1px solid #f1f4f8!important
    }
    .border-lg-0 {
        border: 0!important
    }
    .border-top-lg-0 {
        border-top: 0!important
    }
    .border-right-lg-0 {
        border-right: 0!important
    }
    .border-bottom-lg-0 {
        border-bottom: 0!important
    }
    .border-left-lg-0 {
        border-left: 0!important
    }
}

@media (min-width:1200px) {
    .border-xl {
        border: 1px solid #f1f4f8!important;
        color: rose
    }
    .border-top-xl {
        border-top: 1px solid #f1f4f8!important
    }
    .border-right-xl {
        border-right: 1px solid #f1f4f8!important
    }
    .border-bottom-xl {
        border-bottom: 1px solid #f1f4f8!important
    }
    .border-left-xl {
        border-left: 1px solid #f1f4f8!important
    }
    .border-xl-0 {
        border: 0!important
    }
    .border-top-xl-0 {
        border-top: 0!important
    }
    .border-right-xl-0 {
        border-right: 0!important
    }
    .border-bottom-xl-0 {
        border-bottom: 0!important
    }
    .border-left-xl-0 {
        border-left: 0!important
    }
}

.border-white-20 {
    border-color: hsla(0, 0%, 100%, .2)!important
}

.border-gray-300 {
    border-color: #d9e2ef!important
}

.border-gray-600 {
    border-color: #cecece!important
}

.border-gray-800 {
    border-color: #384c74!important
}

.border-gray-800-50 {
    border-color: rgba(56, 76, 116, .5)!important
}

.img-skewed {
    perspective: 1500px
}

.img-skewed-left {
    perspective-origin: 0
}

.img-skewed-left .img-skewed-item {
    transform: rotateY(-35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.img-skewed-right {
    perspective-origin: 100%
}

.img-skewed-right .img-skewed-item {
    transform: rotateY(35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.overlay {
    position: relative
}

.overlay:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit
}

.overlay-black:before {
    background-color: #161c2d
}

.overlay-white:before {
    background-color: #fff
}

.overlay-primary:before {
    background-color: #165985
}

.overlay-10:before {
    opacity: .1
}

.overlay-20:before {
    opacity: .2
}

.overlay-30:before {
    opacity: .3
}

.overlay-40:before {
    opacity: .4
}

.overlay-50:before {
    opacity: .5
}

.overlay-60:before {
    opacity: .6
}

.overlay-70:before {
    opacity: .7
}

.overlay-80:before {
    opacity: .8
}

.overlay-90:before {
    opacity: .9
}

.lift {
    transition: box-shadow .25s ease, transform .25s ease
}

.lift:focus,
.lift:hover {
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1)!important;
    transform: translate3d(0, -3px, 0)
}

.lift-lg:focus,
.lift-lg:hover {
    box-shadow: 0 2rem 5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .05)!important;
    transform: translate3d(0, -5px, 0)
}

.top-0 {
    top: 0!important
}

.right-0 {
    right: 0!important
}

.bottom-0 {
    bottom: 0!important
}

.left-0 {
    left: 0!important
}

.shadow-light {
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .05)!important
}

.shadow-light-lg {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .05)!important
}

.shadow-dark {
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .15)!important
}

.shadow-dark-lg {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15)!important
}

.shadow-lift {
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1)!important
}

.mw-25 {
    max-width: 25%!important
}

.vw-25 {
    width: 25vw!important
}

.mw-50 {
    max-width: 50%!important
}

.vw-50 {
    width: 50vw!important
}

.mw-75 {
    max-width: 75%!important
}

.vw-75 {
    width: 75vw!important
}

.mw-100 {
    max-width: 100%!important
}

.vw-100 {
    width: 100vw!important
}

.mw-auto {
    max-width: auto!important
}

.vw-auto {
    width: autovw!important
}

.mw-110 {
    max-width: 110%!important
}

.vw-110 {
    width: 110vw!important
}

.mw-120 {
    max-width: 120%!important
}

.vw-120 {
    width: 120vw!important
}

.mw-130 {
    max-width: 130%!important
}

.vw-130 {
    width: 130vw!important
}

.mw-140 {
    max-width: 140%!important
}

.vw-140 {
    width: 140vw!important
}

.mw-150 {
    max-width: 150%!important
}

.vw-150 {
    width: 150vw!important
}

@media (min-width:576px) {
    .h-sm-25 {
        height: 25%!important
    }
    .w-sm-25 {
        width: 25%!important
    }
    .mw-sm-25 {
        max-width: 25%!important
    }
    .vw-sm-25 {
        width: 25vw
    }
    .h-sm-50 {
        height: 50%!important
    }
    .w-sm-50 {
        width: 50%!important
    }
    .mw-sm-50 {
        max-width: 50%!important
    }
    .vw-sm-50 {
        width: 50vw
    }
    .h-sm-75 {
        height: 75%!important
    }
    .w-sm-75 {
        width: 75%!important
    }
    .mw-sm-75 {
        max-width: 75%!important
    }
    .vw-sm-75 {
        width: 75vw
    }
    .h-sm-100 {
        height: 100%!important
    }
    .w-sm-100 {
        width: 100%!important
    }
    .mw-sm-100 {
        max-width: 100%!important
    }
    .vw-sm-100 {
        width: 100vw
    }
    .h-sm-auto {
        height: auto!important
    }
    .w-sm-auto {
        width: auto!important
    }
    .mw-sm-auto {
        max-width: auto!important
    }
    .vw-sm-auto {
        width: autovw
    }
    .h-sm-110 {
        height: 110%!important
    }
    .w-sm-110 {
        width: 110%!important
    }
    .mw-sm-110 {
        max-width: 110%!important
    }
    .vw-sm-110 {
        width: 110vw
    }
    .h-sm-120 {
        height: 120%!important
    }
    .w-sm-120 {
        width: 120%!important
    }
    .mw-sm-120 {
        max-width: 120%!important
    }
    .vw-sm-120 {
        width: 120vw
    }
    .h-sm-130 {
        height: 130%!important
    }
    .w-sm-130 {
        width: 130%!important
    }
    .mw-sm-130 {
        max-width: 130%!important
    }
    .vw-sm-130 {
        width: 130vw
    }
    .h-sm-140 {
        height: 140%!important
    }
    .w-sm-140 {
        width: 140%!important
    }
    .mw-sm-140 {
        max-width: 140%!important
    }
    .vw-sm-140 {
        width: 140vw
    }
    .h-sm-150 {
        height: 150%!important
    }
    .w-sm-150 {
        width: 150%!important
    }
    .mw-sm-150 {
        max-width: 150%!important
    }
    .vw-sm-150 {
        width: 150vw
    }
}

@media (min-width:768px) {
    .h-md-25 {
        height: 25%!important
    }
    .w-md-25 {
        width: 25%!important
    }
    .mw-md-25 {
        max-width: 25%!important
    }
    .vw-md-25 {
        width: 25vw
    }
    .h-md-50 {
        height: 50%!important
    }
    .w-md-50 {
        width: 50%!important
    }
    .mw-md-50 {
        max-width: 50%!important
    }
    .vw-md-50 {
        width: 50vw
    }
    .h-md-75 {
        height: 75%!important
    }
    .w-md-75 {
        width: 75%!important
    }
    .mw-md-75 {
        max-width: 75%!important
    }
    .vw-md-75 {
        width: 75vw
    }
    .h-md-100 {
        height: 100%!important
    }
    .w-md-100 {
        width: 100%!important
    }
    .mw-md-100 {
        max-width: 100%!important
    }
    .vw-md-100 {
        width: 100vw
    }
    .h-md-auto {
        height: auto!important
    }
    .w-md-auto {
        width: auto!important
    }
    .mw-md-auto {
        max-width: auto!important
    }
    .vw-md-auto {
        width: autovw
    }
    .h-md-110 {
        height: 110%!important
    }
    .w-md-110 {
        width: 110%!important
    }
    .mw-md-110 {
        max-width: 110%!important
    }
    .vw-md-110 {
        width: 110vw
    }
    .h-md-120 {
        height: 120%!important
    }
    .w-md-120 {
        width: 120%!important
    }
    .mw-md-120 {
        max-width: 120%!important
    }
    .vw-md-120 {
        width: 120vw
    }
    .h-md-130 {
        height: 130%!important
    }
    .w-md-130 {
        width: 130%!important
    }
    .mw-md-130 {
        max-width: 130%!important
    }
    .vw-md-130 {
        width: 130vw
    }
    .h-md-140 {
        height: 140%!important
    }
    .w-md-140 {
        width: 140%!important
    }
    .mw-md-140 {
        max-width: 140%!important
    }
    .vw-md-140 {
        width: 140vw
    }
    .h-md-150 {
        height: 150%!important
    }
    .w-md-150 {
        width: 150%!important
    }
    .mw-md-150 {
        max-width: 150%!important
    }
    .vw-md-150 {
        width: 150vw
    }
}

@media (min-width:992px) {
    .h-lg-25 {
        height: 25%!important
    }
    .w-lg-25 {
        width: 25%!important
    }
    .mw-lg-25 {
        max-width: 25%!important
    }
    .vw-lg-25 {
        width: 25vw
    }
    .h-lg-50 {
        height: 50%!important
    }
    .w-lg-50 {
        width: 50%!important
    }
    .mw-lg-50 {
        max-width: 50%!important
    }
    .vw-lg-50 {
        width: 50vw
    }
    .h-lg-75 {
        height: 75%!important
    }
    .w-lg-75 {
        width: 75%!important
    }
    .mw-lg-75 {
        max-width: 75%!important
    }
    .vw-lg-75 {
        width: 75vw
    }
    .h-lg-100 {
        height: 100%!important
    }
    .w-lg-100 {
        width: 100%!important
    }
    .mw-lg-100 {
        max-width: 100%!important
    }
    .vw-lg-100 {
        width: 100vw
    }
    .h-lg-auto {
        height: auto!important
    }
    .w-lg-auto {
        width: auto!important
    }
    .mw-lg-auto {
        max-width: auto!important
    }
    .vw-lg-auto {
        width: autovw
    }
    .h-lg-110 {
        height: 110%!important
    }
    .w-lg-110 {
        width: 110%!important
    }
    .mw-lg-110 {
        max-width: 110%!important
    }
    .vw-lg-110 {
        width: 110vw
    }
    .h-lg-120 {
        height: 120%!important
    }
    .w-lg-120 {
        width: 120%!important
    }
    .mw-lg-120 {
        max-width: 120%!important
    }
    .vw-lg-120 {
        width: 120vw
    }
    .h-lg-130 {
        height: 130%!important
    }
    .w-lg-130 {
        width: 130%!important
    }
    .mw-lg-130 {
        max-width: 130%!important
    }
    .vw-lg-130 {
        width: 130vw
    }
    .h-lg-140 {
        height: 140%!important
    }
    .w-lg-140 {
        width: 140%!important
    }
    .mw-lg-140 {
        max-width: 140%!important
    }
    .vw-lg-140 {
        width: 140vw
    }
    .h-lg-150 {
        height: 150%!important
    }
    .w-lg-150 {
        width: 150%!important
    }
    .mw-lg-150 {
        max-width: 150%!important
    }
    .vw-lg-150 {
        width: 150vw
    }
}

@media (min-width:1200px) {
    .h-xl-25 {
        height: 25%!important
    }
    .w-xl-25 {
        width: 25%!important
    }
    .mw-xl-25 {
        max-width: 25%!important
    }
    .vw-xl-25 {
        width: 25vw
    }
    .h-xl-50 {
        height: 50%!important
    }
    .w-xl-50 {
        width: 50%!important
    }
    .mw-xl-50 {
        max-width: 50%!important
    }
    .vw-xl-50 {
        width: 50vw
    }
    .h-xl-75 {
        height: 75%!important
    }
    .w-xl-75 {
        width: 75%!important
    }
    .mw-xl-75 {
        max-width: 75%!important
    }
    .vw-xl-75 {
        width: 75vw
    }
    .h-xl-100 {
        height: 100%!important
    }
    .w-xl-100 {
        width: 100%!important
    }
    .mw-xl-100 {
        max-width: 100%!important
    }
    .vw-xl-100 {
        width: 100vw
    }
    .h-xl-auto {
        height: auto!important
    }
    .w-xl-auto {
        width: auto!important
    }
    .mw-xl-auto {
        max-width: auto!important
    }
    .vw-xl-auto {
        width: autovw
    }
    .h-xl-110 {
        height: 110%!important
    }
    .w-xl-110 {
        width: 110%!important
    }
    .mw-xl-110 {
        max-width: 110%!important
    }
    .vw-xl-110 {
        width: 110vw
    }
    .h-xl-120 {
        height: 120%!important
    }
    .w-xl-120 {
        width: 120%!important
    }
    .mw-xl-120 {
        max-width: 120%!important
    }
    .vw-xl-120 {
        width: 120vw
    }
    .h-xl-130 {
        height: 130%!important
    }
    .w-xl-130 {
        width: 130%!important
    }
    .mw-xl-130 {
        max-width: 130%!important
    }
    .vw-xl-130 {
        width: 130vw
    }
    .h-xl-140 {
        height: 140%!important
    }
    .w-xl-140 {
        width: 140%!important
    }
    .mw-xl-140 {
        max-width: 140%!important
    }
    .vw-xl-140 {
        width: 140vw
    }
    .h-xl-150 {
        height: 150%!important
    }
    .w-xl-150 {
        width: 150%!important
    }
    .mw-xl-150 {
        max-width: 150%!important
    }
    .vw-xl-150 {
        width: 150vw
    }
}

.min-vh-100-ie-hack:before {
    content: "";
    min-height: inherit
}

.font-size-sm {
    font-size: .9375rem!important
}

.font-size-lg {
    font-size: 1.1875rem!important
}

@media (max-width:991.98px) {
    .font-size-lg {
        font-size: 1.0625rem
    }
}

.text-xs {
    font-size: .8rem;
}

.text-lg {
    font-size: 1.2rem;
}

.text-gray-100 {
    color: #f9fbfd!important
}

.text-gray-200 {
    color: #f1f4f8!important
}

.text-gray-300 {
    color: #d9e2ef!important
}

.text-gray-400 {
    color: #c6d3e6!important
}

.text-gray-500 {
    color: #abbcd5!important
}

.text-gray-600 {
    color: #869ab8!important
}

.text-gray-700 {
    color: #506690!important
}

.text-gray-800 {
    color: #384c74!important
}

.text-gray-900 {
    color: #2b354f!important
}

.text-white-70 {
    color: hsla(0, 0%, 100%, .7)!important
}

.text-white-75 {
    color: hsla(0, 0%, 100%, .75)!important
}

.text-white-80 {
    color: hsla(0, 0%, 100%, .8)!important
}

.opacity-0 {
    opacity: 0!important
}

.opacity-1 {
    opacity: 1!important
}

[data-aos=img-skewed-item-left],
[data-aos=img-skewed-item-right] {
    opacity: 0;
    transition-property: transform, opacity;
    will-change: transform, opacity
}

[data-aos=img-skewed-item-left].aos-animate,
[data-aos=img-skewed-item-right].aos-animate {
    opacity: 1
}

[data-aos=img-skewed-item-left] {
    transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0)
}

[data-aos=img-skewed-item-left].aos-animate {
    transform: rotateY(-35deg) rotateX(15deg) translateZ(0)
}

[data-aos=img-skewed-item-right] {
    transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0)
}

[data-aos=img-skewed-item-right].aos-animate {
    transform: rotateY(35deg) rotateX(15deg) translateZ(0)
}

.avatar {
    position: relative;
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    font-size: .625rem
}

.avatar:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg)
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #abbcd5;
    color: #fff
}

.avatar-offline:before,
.avatar-online:before {
    content: "";
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%
}

.avatar-offline .avatar-img,
.avatar-online .avatar-img {
    -webkit-mask-image: url(../img/masks/avatar-status.svg);
    mask-image: url(../img/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-online:before {
    background-color: #42ba96
}

.avatar-offline:before {
    background-color: #abbcd5
}

.avatar-xs {
    width: 1rem;
    height: 1rem;
    font-size: .33333rem
}

.avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
    font-size: .5rem
}

.avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1rem
}

.avatar-xl,
.avatar-xxl {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem
}

@media (min-width:768px) {
    .avatar-xxl {
        width: 5rem;
        height: 5rem;
        font-size: 1.66667rem
    }
}

.avatar.avatar-4by3 {
    width: 2.5rem
}

.avatar-xs.avatar-4by3 {
    width: 1.33333rem
}

.avatar-sm.avatar-4by3 {
    width: 2rem
}

.avatar-lg.avatar-4by3 {
    width: 4rem
}

.avatar-xl.avatar-4by3 {
    width: 5.33333rem
}

.avatar-xxl.avatar-4by3 {
    width: 6.66667rem
}

.avatar-group {
    display: inline-flex
}

.avatar-group .avatar+.avatar {
    margin-left: -.46875rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.25rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -.375rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -.75rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1rem
}

.avatar-group .avatar-xxl+.avatar-xxl {
    margin-left: -1.25rem
}

.avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(../img/masks/avatar-group.svg);
    mask-image: url(../img/masks/avatar-group.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-group .avatar:hover {
    -webkit-mask-image: none;
    mask-image: none;
    z-index: 1
}

.avatar-group .avatar:hover+.avatar {
    -webkit-mask-image: url(../img/masks/avatar-group-hover.svg);
    mask-image: url(../img/masks/avatar-group-hover.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-group .avatar:hover+.avatar:last-child {
    -webkit-mask-image: url(../img/masks/avatar-group-hover-last.svg);
    mask-image: url(../img/masks/avatar-group-hover-last.svg)
}

.collapse-chevron {
    display: inline-block;
    transition: all .2s ease-in-out
}

[aria-expanded=true] .collapse-chevron {
    transform: rotate(-180deg)
}

.device,
.device>.img-fluid {
    position: relative
}

.device:before {
    content: "";
    background-color: #f9fbfd
}

.device-screen,
.device:before {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover
}

.device-iphonex:before,
.device-iphonex>.device-screen {
    top: 7.784431138%;
    left: 16.4021164%;
    width: 66.137566137%;
    height: 80.838323353%
}

.device-macbook:before,
.device-macbook>.device-screen {
    top: 11.53846154%;
    left: 13.38709677%;
    width: 73.548387096%;
    height: 73.076923076%
}

.device-combo {
    position: relative
}

.device-combo>.device {
    position: absolute
}

.device-combo-iphonex-iphonex {
    padding-bottom: 130.250482%
}

.device-combo-iphonex-iphonex>.device-iphonex:first-child {
    bottom: 0;
    left: 0;
    width: 65.5260116%;
    z-index: 1
}

.device-combo-iphonex-iphonex>.device-iphonex:last-child {
    top: 0;
    right: 0;
    width: 72.8323699%
}

.device-combo-iphonex-macbook,
.device-combo-macbook-iphonex {
    padding-bottom: 62.4260355%
}

.device-combo-iphonex-macbook>.device-macbook,
.device-combo-macbook-iphonex>.device-macbook {
    width: 91.7159763%
}

.device-combo-iphonex-macbook>.device-iphonex,
.device-combo-macbook-iphonex>.device-iphonex {
    width: 27.9585799%;
    z-index: 1
}

.device-combo-macbook-iphonex>.device-macbook {
    top: 0;
    left: 0
}

.device-combo-iphonex-macbook>.device-macbook {
    top: 0;
    right: 0
}

.device-combo-macbook-iphonex>.device-iphonex {
    bottom: 0;
    right: 0
}

.device-combo-iphonex-macbook>.device-iphonex {
    bottom: 0;
    left: 0
}

body.compensate-for-scrollbar {
    margin-right: 0!important
}

.fancybox-container .fancybox-bg {
    background-color: hsla(0, 0%, 100%, .97)
}

.fe-lg {
    font-size: 1.3125rem
}

@media (min-width:768px) {
    .fe-lg {
        font-size: 1.125
    }
}

.bg-dark .footer-brand {
    filter: brightness(0) invert(1)
}

.flickity-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(1.6em + 1.625rem + 2px);
    height: calc(1.6em + 1.625rem + 2px);
    background-color: #165985;
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .05);
    color: #fff
}

.flickity-button:after,
.flickity-button:before {
    font-family: Feather
}

.flickity-button:focus,
.flickity-button:hover {
    background-color: #165985;
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1)
}

@media (min-width:768px) {
    .flickity-button.previous {
        left: 0;
        transform: translate(-50%, -50%)
    }
}

.flickity-button.previous:before {
    content: "\e910"
}

@media (min-width:768px) {
    .flickity-button.next {
        right: 0;
        transform: translate(50%, -50%)
    }
}

.flickity-button.next:before {
    content: "\e912"
}

.flickity-button-icon {
    display: none
}

.flickity-button-white .flickity-button {
    background-color: #fff;
    color: #165985
}

.flickity-button-bottom .flickity-button {
    top: auto;
    bottom: 1.5rem;
    transform: none
}

.flickity-button-bottom .flickity-button.previous {
    left: auto;
    right: 6.375rem
}

.flickity-button-bottom .flickity-button.next {
    left: auto;
    right: 2.5rem
}

.flickity-button-inset .flickity-button {
    transform: translateY(-50%)
}

.flickity-button-inset .flickity-button.previous {
    left: 1.5rem
}

.flickity-button-inset .flickity-button.next {
    right: 1.5rem
}

.flickity-viewport-visible .flickity-viewport {
    overflow: visible
}

[data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
    transition: all .5s ease
}

.hljs {
    background-color: transparent
}

.icon>svg {
    width: 3rem;
    height: 3rem
}

.icon[class*=text-]>svg [fill]:not([fill=none]) {
    fill: currentColor!important
}

.icon-xs>svg {
    width: 1.5rem;
    height: 1.5rem
}

.icon-sm>svg {
    width: 2.25rem;
    height: 2.25rem
}

.icon-lg>svg {
    width: 4rem;
    height: 4rem
}

.icon-xl>svg {
    width: 5rem;
    height: 5rem
}

.icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4.1875rem;
    height: 4.1875rem;
    border-radius: 50%
}

.icon-circle>.fe {
    font-size: 1.875rem
}

.screenshot {
    border-radius: .625rem;
    box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, .1), 16px 40px 75px -40px rgba(0, 0, 0, .2)
}

.shape {
    position: absolute
}

.shape>img,
.shape>svg {
    display: block
}

.shape:not([class*=blur]) {
    overflow: hidden
}

.shape:not([class*=blur])>img,
.shape:not([class*=blur])>svg {
    transform: scale(1.001)
}

@media (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .shape>svg {
        display: none
    }
}

.shape-top {
    top: -.75px;
    right: -.75px;
    left: -.75px
}

.shape-top>img,
.shape-top>svg {
    transform-origin: top center
}

.shape-right {
    top: -.75px;
    right: -.75px;
    bottom: -.75px
}

.shape-right>img,
.shape-right>svg {
    transform-origin: right center;
    margin-left: auto
}

.shape-bottom {
    right: -.75px;
    bottom: -.75px;
    left: -.75px
}

.shape-bottom>img,
.shape-bottom>svg {
    transform-origin: bottom center
}

.shape-left {
    top: -.75px;
    bottom: -.75px;
    left: -.75px
}

.shape-left>img,
.shape-left>svg {
    transform-origin: left center
}

.shape-fluid-x>img,
.shape-fluid-x>svg {
    width: calc(100% + 1.5px);
    height: auto
}

.shape-fluid-y>img,
.shape-fluid-y>svg {
    height: calc(100% + 1.5px);
    width: auto
}

.shape-fluid-y {
    width: 100%;
    pointer-events: none
}

.shape-flip-x {
    transform: scaleX(-1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.shape-blur-1 {
    top: 0;
    right: 0;
    left: 0
}

.shape-blur-1>img,
.shape-blur-1>svg {
    width: 100%;
    height: auto
}

@media (min-width:768px) {
    .shape-blur-1 {
        bottom: 0;
        left: 50%;
        right: auto
    }
    .shape-blur-1>img,
    .shape-blur-1>svg {
        width: auto;
        height: 100%
    }
}

.shape-blur-2 {
    top: 0;
    right: 0;
    left: 0
}

.shape-blur-2>img,
.shape-blur-2>svg {
    width: 100%;
    height: auto
}

@media (min-width:768px) {
    .shape-blur-2 {
        bottom: 0;
        right: 50%;
        left: auto
    }
    .shape-blur-2>img,
    .shape-blur-2>svg {
        width: auto;
        height: 100%;
        float: right
    }
}

.shape-blur-4 {
    top: 0;
    bottom: 0;
    left: 50%
}

.shape-blur-4>img,
.shape-blur-4>svg {
    width: auto;
    height: 100%;
    transform: translateX(-50%) scale(1.3)
}

@media (min-width:992px) {
    .sidenav {
        position: fixed;
        top: 84px;
        height: calc(100vh - 84px);
        overflow: auto
    }
}

@media (min-width:992px) {
    .sidenav-left {
        left: 0
    }
}

@media (min-width:992px) {
    .sidenav-right {
        right: 0
    }
}


#merchantModal a img {
    width: 8rem;
    height: auto;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

ul.navbar-nav {
    font-size: 1rem !important;
}

.navbar-brand-img {
    width: 8rem !important;
    height: auto !important;
}

div#preloader {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFF;
    z-index: 1900;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.lang-span {
    cursor: pointer;
}

@media (max-width:576px) {
    .navbar-brand-img {
        width: 6rem !important;
        height: auto !important;
    }
    .nav-item.lang{
        display: none;
    }
}