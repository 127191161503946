/* You can add global styles to this file, and also import other style files */
/* [BACKGROUND] */
#numpad-back {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 5%;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  z-index: 1950;
}

.mobile-pt{
    padding-top: 30% !important;
}

input.no-border {
    outline: 0;
    border-width: 0 0 0 0;
  }

  .max-w-300{
      width:80%;
      min-width: 300px;
      max-width: 320px;
  }


/* [NUMPAD] */
#numpad-wrap,
#numpad-display {
  /* FONT - REMOVE THIS TO FOLLOW YOUR CURRENT THEME */
  font-family: 'Arial', sans-serif;
}

#numpad-wrap {
  max-width: 300px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
}

/* [NUMBER DISPLAY] */
#numpad-display {
  box-sizing: border-box;
  width: 100%;
  padding: 3px;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 10px;
}

/* [NUMBER BUTTONS] */
#numpad-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.numpad-btn {
  box-sizing: border-box;
  width: 33.33%;
  padding: 10px;
  background: #fff;
  color: #165985;
  text-align: center;
  font-size: 1.5em;
}

.numpad-btn.zero {
  width: 50%;
}

.numpad-btn.zeroN {
  width: 75%;
}

.numpad-btn.ninja {
  display: none;
}

.numpad-btn.ng {
  background: #333;
}

.numpad-btn.cx {
  background: #fff;
  border: 1px solid #C73D32;
}

.numpad-btn.ok {
  background: #1FA83A;
  border: 1px solid #1FC72D;
}

.numpad-btn:hover {
  cursor: pointer;
}

.border-tl-r-10 {
  border-top-left-radius: 10px;
}

.border-tr-r-10 {
  border-top-right-radius: 10px;
}

.pad-l-10 {
  padding-left: 10px;
}

.pad-r-10 {
  padding-right: 10px;
}

.border-tl-r-5 {
  border-top-left-radius: 5px;
}

.border-tr-r-5 {
  border-top-right-radius: 5px;
}

.pad-l-5 {
  padding-left: 5px;
}

.pad-r-5 {
  padding-right: 5px;
}

.spinner-border-lg {
  width: 2.5rem;
  height: 2.5rem;
  border-width: .2em;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.itemList{
  cursor: pointer !important;
}

.modal-backdrop{
  display: none !important;
}


